<template>
  <v-dialog
    v-model="openModal"
    transition="dialog-bottom-transition"
    :width="screenWidth"
    persistent
  >
    <div class="bg-content">
      <v-btn
        class="close-button"
        @click="$emit('closeModal')"
        icon
      >
        <v-icon
          color="#000"
          size="20px"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <p class="txt-title po-regular">Need info?</p>
      <p class="txt-title po-regular mb-9">Send us an Email</p>
      <form
        @submit.prevent="sendEmail"
        class="content-form-request"
      >
        <div>
          <p class="txt-label po-regular">Name*</p>
          <v-text-field
            v-model="nombre"
            name="nombre"
            placeholder="Name"
            solo
            dense
            hide-details=""
            class="mb-5 input-style po-regular"
            background-color="#fff"
          />
        </div>
        <div>
          <p class="txt-label po-regular">Email*</p>
          <v-text-field
            v-model="correo"
            name="correo"
            placeholder="Email"
            solo
            dense
            hide-details=""
            class="mb-5 input-style po-regular"
            background-color="#fff"
          />
        </div>
        <div>
          <p class="txt-label po-regular">Message*</p>
          <v-textarea
            v-model="mensaje"
            name="mensaje"
            placeholder="Message"
            solo
            dense
            class="mb-3 input-style po-regular"
            rows="4"
            counter="500"
            background-color="#fff"
          />
        </div>
        <div class="content-button-franchising display-flex align-items-center justify-content-center mt-7">
          <v-btn
            type="submit"
            class="button-send po-regular"
            elevation="0"
            :loading="bLoading"
            :disabled="!validateForm"
          >
            Submit
          </v-btn>
        </div>
      </form>
    </div>
  </v-dialog>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "RequestLayout",
  props: {
    openModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      //variables
      screenWidth: 0,
      nombre: "",
      correo: "",
      mensaje: "",
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    sendEmail: function (e) {
      this.bLoading = true;

      emailjs
        .sendForm(
          "service_nhbz5lo",
          "template_smjq5qr",
          e.target,
          "user_PIpQsjbgK2NtqIL9VXeoQ"
        )
        .then(
          (result) => {
            this.bLoading = false;

            this.$swal(
              "Success!",
              "The email has been sent successfully",
              "success"
            );
            this.nombre = "";
            this.correo = "";
            this.mensaje = "";
            this.$emit("closeModal");
          },
          (error) => {
            this.bLoading = false;

            this.$swal(
              "Error!",
              "There are problems sending the email",
              "success"
            );
            this.nombre = "";
            this.correo = "";
            this.mensaje = "";
            this.$emit("closeModal");
          }
        );
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = "50%";
      } else {
        this.screenWidth = "100%";
      }
    },
  },
  computed: {
    validateForm: function () {
      return this.nombre != "" && this.correo != "" && this.mensaje != "";
    },
  },
};
</script>

<style scoped>
.button-send {
  width: 100%;
  background: #dd4999 0% 0% no-repeat padding-box !important;
  border-radius: 30px;
  text-align: left;
  font-size: 16px !important;
  text-transform: initial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-title {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.bg-content {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.txt-label {
  text-align: left;
  color: #000;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0px;
}
</style>

<style>
.content-form-request .input-style input::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style input:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style input::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style textarea::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style textarea:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style textarea::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000 !important;
  opacity: 0.2;
}

.content-form-request .input-style input {
  color: #000 !important;
  font-size: 14px;
}

.content-form-request .input-style textarea {
  color: #000 !important;
  font-size: 14px;
}

.content-form-request .v-input__slot {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
</style>