<template>
  <div class="position-relative">
    <div class="content-image">
      <img
        src="@/assets/images/about/sombra.png"
        alt=""
      >
    </div>
    <v-container
      fluid
      class="v-container"
    >
      <v-row
        v-for="(item, index) in array"
        :key="index"
      >
        <v-col
          class="pa-0"
          cols="12"
          sm="5"
        >
          <div class="content-title display-flex align-items-center full-height border-left">
            <v-container
              fluid
              class="p-left-container p-right-container"
            >
              <v-row>
                <v-col>
                  <p class="txt-title po-regular">{{ item.title }}</p>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          sm="7"
        >
          <div class="content-description display-flex align-items-center full-height border-right">
            <v-container class="p-left-container p-right-container">
              <v-row>
                <v-col>
                  <p
                    class="txt-description po-regular"
                    v-if="item.description"
                    v-html="item.description"
                  />
                  <div
                    v-if="item.arr"
                    class="full-width"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(itm,i) in item.arr"
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          <div class="expansion-txt-title po-regular">
                            {{ itm.title }}
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="expansion-txt-description po-regular">
                            {{ itm.description }}
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "InformationLayout",
  data() {
    return {
      //variables
      array: [
        {
          arr: undefined,
          title: "About",
          description:
            "loz takos is a new urban taqueria concept, specializing in traditional authentic mexican real street food. <br/> <br/> committed to serving high quality ingredients we offer a wide variety of menu items specializing in tacos, tortas, burritos and quesadillas, providing fine dining quality at the cost and convenience of “faster” food. our goal is to close the gap there is between non-authentic mexican food and true mexican street food making it available through out canada.",
        },
        {
          arr: undefined,
          title: "Our vision",
          description:
            "to become the first truly mexican restaurant chain to serve mexican street food in a modern urban atmosphere.",
        },
        {
          arr: [
            {
              title: "Quality",
              description: "",
            },
            {
              title: "Speed",
              description:
                "the speed of service is essential in the restaurant industry. thanks to our systems and procedures we are able to make real mexican street food as fast as we can",
            },
            {
              title: "Variety",
              description: "",
            },
          ],
          title: "Competitive EDGE",
          description: undefined,
        },
      ],
    };
  },
};
</script>

<style scoped>
.content-image {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.v-expansion-panel::before {
  box-shadow: none;
}

.expansion-txt-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.expansion-txt-description {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  text-transform: lowercase;
  opacity: 1;
}

.content-title {
  padding-left: 0px;
}

.content-description {
  padding: 50px 0px;
}

.txt-title {
  text-align: left;
  font-size: 40px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  text-transform: lowercase;
  opacity: 1;
  margin-bottom: 0px;
}

.border-left {
  border-bottom: 1px solid #c4c4c4;
}

.border-right {
  border-left: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

@media (max-width: 960px) {
  .content-title {
    padding: 50px 0px;
  }

  .txt-title {
    font-size: 25px;
  }

  .txt-description {
    font-size: 14px;
  }

  .expansion-txt-title {
    font-size: 16px;
  }

  .expansion-txt-description {
    font-size: 14px;
  }
}
</style>