<template>
  <div class="content-bg-piedra position-relative">
    <v-container fluid class="v-container">
      <v-row>
        <v-col cols="12">
          <div v-for="(item, i) in aAbout" :key="i" class="content-quality">
            <div class="content-left">
              <p class="txt-title-section po-regular">
                <span style="color: #C9DA2D;">[</span>
                {{ item.title }}
                <span style="color: #C9DA2D;">]</span>
              </p>
              <p class="txt-description po-regular">
                {{ item.description }}
              </p>
            </div>
            <div class="content-right">
              <img :src="item.background" class="img-quality" />
            </div>
          </div>
          <!-- <div class="remove-padding display-flex align-items-center justify-content-center full-height pl-9 pr-9">
            <div>
              <p class="txt-title-section po-regular">
                <span style="color: #C9DA2D;">[</span>
                Quality
                <span style="color: #C9DA2D;">]</span>
              </p>
              <p class="txt-description po-regular">We take pride in serving our food made-to-order, prepared and assembled to perfection. from food and paper products to distribution services, all our suppliers must meet stringent quality, value, and safety standards.</p>
              <div style="margin-top: 100px">
                <p class="txt-title-section po-regular">
                  <span style="color: #C9DA2D;">[</span>
                  Speed
                  <span style="color: #C9DA2D;">]</span>
                </p>
                <p class="txt-description po-regular">The speed of service is essential in the restaurant industry. thanks to our systems and procedures we are able to make real mexican street food as fast as we can.</p>
              </div>
              <div style="margin-top: 100px">
                <p class="txt-title-section po-regular">
                  <span style="color: #C9DA2D;">[</span>
                  Variety
                  <span style="color: #C9DA2D;">]</span>
                </p>
                <p class="txt-description po-regular">We offer a large variety of tacos, tortas and burritos made with different flavors, sauces and toppings. we also have up to five different sides as well as delicious options for salads and dessert. <br> <br> Loz takos offers ongoing support, proven business systems and procedures to assist you in managing your business to success.</p>
              </div>
            </div>
          </div> -->
          <!-- <div id="content-fixed">
            <div class="parallax">
              <img
                src="@/assets/images/about/tostada.png"
                alt=""
              >
            </div>
          </div>
          <div id="content-fixed">
            <div class="parallax">
              <img
                src="@/assets/images/about/tostada.png"
                alt=""
              >
            </div>
          </div>
          <div id="content-fixed">
            <div class="parallax">
              <img
                src="@/assets/images/about/tostada.png"
                alt=""
              >
            </div>
          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "QualityLayout",
  data() {

    return {
      aAbout: [
        {
          id: 1,
          title: 'Quality',
          description: 'We take pride in serving our food made-to-order, prepared and assembled to perfection. From food and paper products to distribution services, all our suppliers must meet stringent quality, value, and safety standards.',
          background: [require("@/assets/images/about/Quality.jpeg")],
        },
        {
          id: 2,
          title: 'Speed',
          description: 'The speed of service is essential in the restaurant industry. Thanks to our systems and procedures we are able to make real mexican street food as fast as we can.',
          background: [require("@/assets/images/about/Speed.jpeg")],
        },
        {
          id: 3,
          title: 'Variety',
          description: 'We offer a large variety of tacos, tortas and burritos made with different flavors, sauces and toppings. we also have up to five different sides as well as delicious options for salads and dessert.',
          background: [require("@/assets/images/about/Variety.jpeg")],
        }
      ]
    };
  },
  // created() {
  //   window.addEventListener("scroll", this.fixed);
  //   this.fixed();
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.fixed);
  // },
};
</script>

<style scoped>
.content-quality {
  display: flex;
  height: 300px;
}

.content-left,
.content-right {
  width: 50%;
  max-width: 50%;
  min-height: 50%;
  padding: 20px;
}

.parallax {
  width: 100%;
  height: 400px;

}

.img-quality {
  width: 100%;
  height: auto;

}

@supports (object-fit: cover) {
  .img-quality {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: bold;
}

@media (max-width: 600px) {
  /* .txt-description {
    font-size: 18px;
  }

  .remove-padding {
    padding: 15px !important;
  }

  #content-fixed {
    transform: translate(0px) !important;
  } */

}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-quality {
    display: block;
    height: auto;
  }

  .content-left,
  .content-right {
    width: 100%;
    height: 300px;
    max-width: 100%;
    min-height: 100%;
    padding: 20px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-quality {
    display: block;
    height: auto;
  }

  .content-left,
  .content-right {
    width: 100%;
    height: 300px;
    max-width: 100%;
    min-height: 100%;
    padding: 20px;
  }
}


/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>