<template>
  <div style="position:relative;">
    <navbar-component />
    <banner-component
      bannerTitle='CATERING'
      :bannerImage='bannerImage'
    />
    <want-tacos-layout />
    <drill-layout />
    <whatever-layout />
    <footer-component />
    <slide-navbar-component />
    <slide-cart-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
import BannerComponent from "@/components/Banner.vue";
import WantTacosLayout from "@/layouts/catering/WantTacos.vue";
import DrillLayout from "@/layouts/catering/Drill.vue";
import WhateverLayout from "@/layouts/catering/Whatever.vue";


export default {
  name: "CateringView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/catering/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    WantTacosLayout,
    DrillLayout,
    WhateverLayout,
    FooterComponent,
  },
};
</script>