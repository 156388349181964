<template>
  <div
    id="navbar"
    class="content-navbar fixed-top"
  >
    <div
      v-if="!showMenu"
      class="display-flex align-items-center justify-content-center full-height"
    >
      <div
        v-for="(item, index) in options"
        :key="index"
      >
        <p
          v-if="item.link == $route.name"
          :hidden="index == 4"
          class="txt-options-active po-bold mb-0 ml-2 mr-2"
        >
          [ {{ item.text }} ]
        </p>
        <p
          v-else
          :hidden="index == 4"
          class="txt-options po-bold mb-0 ml-2 mr-2"
          @click="$router.push({ name: item.link })"
        >
          <span style="color: #c9da2d;">[</span>
          {{ item.text }}
          <span style="color: #c9da2d;">]</span>
        </p>
        <img
          v-if="index == 4"
          class="cursor-pointer"
          src="@/assets/images/aguacate.png"
          width="30px"
          alt=""
          @click="$router.push({ name: 'Home' }).catch((err) => {})"
        />
      </div>
      <!-- button-cart  -->
      <v-btn
        class="button-cart"
        dark
        icon
        @click="openSlideCart"
      >
        <v-icon
          color="#fff"
          size="20px"
        >
          mdi-cart-outline
        </v-icon>
      </v-btn>
    </div>
    <div class="display-flex align-items-center full-height">
      <v-btn
        v-if="showMenu"
        class="button-menu po-regular mb-2"
        text
        @click="openSlideNavbar"
      >
        <v-icon
          color="#fff"
          size="20px"
          class="mr-1"
        >
          mdi-menu
        </v-icon>
        Menu
      </v-btn>
      <v-spacer />
      <v-btn
        class="button-cart"
        dark
        icon
        @click="openSlideCart"
      >
        <div
          v-if="countOrder > 0"
          class="content-badge"
          v-html="countOrder"
        />
        <v-icon
          color="#fff"
          size="20px"
        >
          mdi-cart-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      //variables
      countOrder: 0,
      showMenu: false,
      options: [
        {
          text: "HOME",
          link: "Home",
        },
        {
          text: "ABOUT US",
          link: "About",
        },
        {
          text: "CATERING",
          link: "Catering",
        },
        {
          text: "MENU",
          link: "Menu",
        },
        {
          text: "",
          link: "Home",
        },
        {
          text: "GALLERY",
          link: "Gallery",
        },
        {
          text: "FRANCHISNG",
          link: "Franchising",
        },
        {
          text: "CONTACT US",
          link: "Contact",
        },
        {
          text: "FAQ",
          link: "Faq",
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.changeBackgroundColor);
    this.changeBackgroundColor();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("scroll", this.changeBackgroundColor);
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.countOrder = this.$store.state.productsCartArr.length;
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    openSlideNavbar: function () {
      this.$store.commit("setSlideNavbar", true);
    },
    openSlideCart: function () {
      const self = this;

      if (this.$store.state.productsCartArr.length > 0) {
        this.$store.commit("setSlideCart", true);
      } else {
        this.$swal("Empty cart", "Add products to view cart.", "info").then(
          function () {
            self.$router
              .push({
                name: "Menu",
              })
              .catch((e) => {});
          }
        );
      }
    },
    changeBackgroundColor: function () {
      let nav = document.getElementById("navbar");

      if (nav) {
        nav.classList.toggle(
          "scrolled",
          document.documentElement.scrollTop > nav.offsetHeight
        );
      }
    },
  },
  computed: {
    count: function () {
      return this.$store.state.productsCartArr;
    },
  },
  watch: {
    count: function () {
      if (this.count) {
        this.countOrder = this.count.length;
      }
    },
  },
};
</script>

<style>
.content-badge {
  background-color: red;
  position: absolute;
  top: -10px;
  right: -5px;
  border-radius: 100%;
  padding: 3px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-cart {
  position: absolute;
  right: 15px;
  top: 17px;
}

.txt-options {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-options-active {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.txt-options:hover {
  color: #dd4999;
  cursor: pointer;
  text-decoration: underline;
}

.fixed-top {
  position: fixed;
  top: 0px;
}

.fixed-top.scrolled {
  background-color: #000;
  transition: background-color 200ms linear;
}

.border-menu {
  /* 464646 */
  border-right: 1px solid #464646;
  border-bottom: 1px solid #464646;
  padding: 19px;
}

.border-logo {
  border-bottom: 1px solid #464646;
  padding: 30px;
}

.border-order {
  border-bottom: 1px solid #464646;
  padding: 23px;
}

.border-cart {
  border-left: 1px solid #464646;
  border-bottom: 1px solid #464646;
  padding: 23px;
}

.button-order-now {
  background-color: #dd4999 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  text-transform: initial !important;
  color: #fff !important;
}

.button-menu {
  color: #fff !important;
  text-transform: initial !important;
  font-size: 18px !important;
}

.content-navbar {
  background-color: #000000;
  width: 100%;
  z-index: 2;
  height: 70px;
  position: relative;
}

.content-image {
  width: 100px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 599px) {
  .hidden-sm {
    display: none;
  }
  .border-order {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
   .hidden-sm {
    display: none;
  }
  .border-order {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
