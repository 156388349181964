<template>
  <div class="position-relative">
    <navbar-component />
    <banner-component
      bannerTitle='ABOUT US'
      :bannerImage='bannerImage'
    />
    <description-layout />
    <vision-mision-layout />
    <competitive-layout />
    <quality-layout />
    <faq-component />
    <!-- <information-layout /> -->
    <footer-component />
    <slide-navbar-component />
    <slide-cart-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import BannerComponent from "@/components/Banner.vue";
import DescriptionLayout from "@/layouts/about/Description.vue";
import VisionMisionLayout from "@/layouts/about/VisionMision.vue";
import InformationLayout from "@/layouts/about/Information.vue";
import QualityLayout from "@/layouts/about/Quality.vue";
import CompetitiveLayout from "@/layouts/about/Competitive.vue";
import FaqComponent from "@/components/Faq.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "AboutView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/about/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    DescriptionLayout,
    BannerComponent,
    // ParallaxLayout,
    InformationLayout,
    QualityLayout,
    CompetitiveLayout,
    VisionMisionLayout,
    FaqComponent,
    FooterComponent,
  },
};
</script>