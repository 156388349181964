<template>
  <div class="content-form">
    <div class="content-title display-flex align-items-center full-height mb-5">
      <div class="text-left">
        <p v-if="!dMovil" class="txt-title po-regular">
          <span style="color: #C9DA2D;">[</span>
          WHATEVER IT IS, LET US  <br> TAKE CARE OF IT!
          <span style="color: #C9DA2D;">]</span>
        </p>
        <p v-if="dMovil" class="txt-title-movil po-regular">
          <span style="color: #C9DA2D;">[</span>
          WHATEVER IT IS, LET US TAKE <br> CARE OF IT!
          <span style="color: #C9DA2D;">]</span>
        </p>
        <p class="txt-description po-regular">Tell us what about your event</p>
      </div>
    </div>
    <div class="position-relative">
      <form @submit.prevent="sendEmail">
        <p class="txt-label po-regular">Name*</p>
        <v-text-field name="nombre" v-model="nombre" placeholder="Name" solo dense hide-details=""
          class="mb-5 input-style" background-color="#262626"></v-text-field>
        <p class="txt-label po-regular">Email*</p>
        <v-text-field name="correo" v-model="correo" placeholder="Email" solo dense hide-details=""
          class="mb-5 input-style" background-color="#262626"></v-text-field>
        <p class="txt-label po-regular">Phone*</p>
        <v-text-field name="telefono" v-model="telefono" placeholder="Phone" solo dense hide-details=""
          class="mb-5 input-style" background-color="#262626" @keypress="soloNumbers"></v-text-field>
        <p class="txt-label po-regular">Tell us about your event*</p>
        <v-textarea name="comentarios" v-model="comentarios" placeholder="Tell us about your event*" solo dense
          class="mb-3 input-style" rows="4" counter="500" background-color="#262626"></v-textarea>
        <div class="content-button-catering display-flex align-items-center justify-content-center">
          <v-btn type="submit" class="button-send-catering" :loading="bLoading" :disabled="!validateForm">
            Submit
          </v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "FormLayout",
  data() {
    return {
      //variables
      bLoading: false,
      nombre: "",
      correo: "",
      telefono: "",
      comentarios: "",
      dMovil: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 440) {
        this.dMovil = true;
      } else {
        this.dMovil = false;
      }
    },
    sendEmail: function (e) {
      this.bLoading = true;

      emailjs
        .sendForm(
          "service_nhbz5lo",
          "template_v5zfzdr",
          e.target,
          "user_PIpQsjbgK2NtqIL9VXeoQ"
        )
        .then(
          (result) => {
            this.bLoading = false;

            this.$swal(
              "Success!",
              "The email has been sent successfully",
              "success"
            );
            this.nombre = "";
            this.correo = "";
            this.telefono = "";
            this.comentarios = "";
          },
          (error) => {
            this.bLoading = false;

            this.$swal(
              "Error!",
              "There are problems sending the email",
              "success"
            );
            this.nombre = "";
            this.correo = "";
            this.telefono = "";
            this.comentarios = "";
          }
        );
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
  computed: {
    validateForm: function () {
      return (
        this.nombre != "" &&
        this.correo != "" &&
        this.telefono != "" &&
        this.comentarios != ""
      );
    },
  },
};
</script>

<style>
.content-button-catering .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #dd4999 !important;
  opacity: 0.5;
  pointer-events: all;
  cursor: no-drop;
}

.content-form .input-style input::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input {
  color: #fff !important;
  font-size: 14px;
}

.content-form .input-style textarea {
  color: #fff !important;
  font-size: 14px;
}
</style>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-description {
  text-align: left;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0px;
  -webkit-background-clip: text;
  background-image: linear-gradient(360deg, #ffffff 0%, #dd4999 100%);
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.chiles-left {
  position: absolute;
  left: -90px;
  bottom: 0;
  width: 300px;
}

.chiles-right {
  position: absolute;
  right: -150px;
  bottom: 0;
  width: 300px;
}

.bigote {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
}

.content-form {
  background-color: #000;
  padding: 100px 40px;
}

.button-send-catering {
  width: 100%;
  background: #dd4999 0% 0% no-repeat padding-box !important;
  border-radius: 30px;
  text-align: left;
  font-size: 16px !important;
  text-transform: initial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-label {
  text-align: left;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0px;
}

@media (max-width: 960px) {

  .bigote,
  .chiles-left,
  .chiles-right {
    display: none;
  }
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
    font-weight: 500;
  }

  .txt-title-movil {
    text-align: left;
    font-size: 19px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .txt-title {
    font-size: 18px;
  }
}

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .txt-title {
    font-size: 23px;
  }
}
</style>