<template>
  <div class="content-bg-piedra position-relative">
    <div class="content-image">
      <img
        src="@/assets/images/about/sombra.png"
        alt=""
      >
    </div>
    <v-container
      fluid
      class="v-container"
    >
      <v-row>
        <v-col
          class="pa-0"
          cols="12"
          md="6"
        >
          <div>
            <div class="content-image-column1">
              <img
                src="@/assets/images/about/welcome.png"
                alt=""
              >
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div class="remove-padding display-flex align-items-center justify-content-center full-height pl-9 pr-9">
            <div>
              <p class="txt-title-section po-regular">
                <span style="color: #C9DA2D;">[</span>
                About Us
                <span style="color: #C9DA2D;">]</span>
              </p>
              <p class="txt-description po-regular"> Loz takos is a new urban taqueria concept, specializing in traditional authentic mexican real street food. committed to serving high quality ingredients we offer a wide variety of menu items specializing in tacos, tortas, burritos and quesadillas, providing fine dining quality at the cost and convenience of “faster” food. <br /> <br /> Our goal is to close the gap there is between non-authentic mexican food and true mexican street food making it available through out canada.</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DescriptionLayout",
};
</script>

<style scoped>
.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: bold;
}

.content-image-column1 {
  width: 100%;
  height: 550px;
  margin-top: 150px;
}

.content-image-column1 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-column1 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-image {
  width: 100%;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
@media (max-width: 600px) {
  .txt-description {
    font-size: 18px;
  }

  .remove-padding {
    padding: 15px !important;
  }
}

</style>