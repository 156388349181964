
<template>
  <div class="content-gallery">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="content-grid">
            <div v-for="(item, index) in gallery" :key="index" :class="item.type">
              <img :class="(item.effect) ? 'fadein' : 'fadeout'"
                :src="require(`@/assets/images/gallery/${item.id}.jpg`)" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PhotosLayout",
  data() {
    return {
      //variables
      gallery: [
        {
          id: 1,
          type: "big",
          effect: false,
        },
        {
          id: 2,
          type: "vertical",
          effect: false,
        },
        {
          id: 3,
          type: "",
          effect: false,
        },
        {
          id: 4,
          type: "",
          effect: false,
        },
        {
          id: 5,
          type: "vertical",
          effect: false,
        },
        {
          id: 6,
          type: "",
          effect: false,
        },
        {
          id: 7,
          type: "",
          effect: false,
        },
        {
          id: 8,
          type: "",
          effect: false,
        },
        {
          id: 9,
          type: "horizontal",
          effect: false,
        },
        {
          id: 10,
          type: "",
          effect: false,
        },
      ],
      copy: 1,
      random: 1,
    };
  },
  beforeMount() {
    this.interval();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 600) {
        this.gallery = [
          {
            id: 1,
            type: "",
            effect: false,
          },
          {
            id: 2,
            type: "",
            effect: false,
          },
          {
            id: 3,
            type: "",
            effect: false,
          },
          {
            id: 4,
            type: "",
            effect: false,
          },
          {
            id: 5,
            type: "",
            effect: false,
          },
          {
            id: 6,
            type: "",
            effect: false,
          },
          {
            id: 7,
            type: "",
            effect: false,
          },
          {
            id: 8,
            type: "",
            effect: false,
          },
          {
            id: 9,
            type: "",
            effect: false,
          },
          {
            id: 10,
            type: "",
            effect: false,
          },
        ];
      } else if (window.innerWidth > 600 && window.innerWidth < 1568) {
        this.gallery = [
          {
            id: 1,
            type: "vertical",
            effect: false,
          },
          {
            id: 2,
            type: "",
            effect: false,
          },
          {
            id: 3,
            type: "",
            effect: false,
          },
          {
            id: 4,
            type: "",
            effect: false,
          },
          {
            id: 5,
            type: "",
            effect: false,
          },
          {
            id: 6,
            type: "",
            effect: false,
          },
          {
            id: 7,
            type: "",
            effect: false,
          },
          {
            id: 8,
            type: "",
            effect: false,
          },
          {
            id: 9,
            type: "",
            effect: false,
          },
          {
            id: 10,
            type: "horizontal",
            effect: false,
          },
        ];
      } else if (window.innerWidth > 1569 && window.innerWidth < 1800) {
        this.gallery = [
          {
            id: 1,
            type: "",
            effect: false,
          },
          {
            id: 2,
            type: "",
            effect: false,
          },
          {
            id: 3,
            type: "",
            effect: false,
          },
          {
            id: 4,
            type: "",
            effect: false,
          },
          {
            id: 5,
            type: "",
            effect: false,
          },
          {
            id: 6,
            type: "",
            effect: false,
          },
          {
            id: 7,
            type: "",
            effect: false,
          },
          {
            id: 8,
            type: "",
            effect: false,
          },
          {
            id: 9,
            type: "",
            effect: false,
          },
          {
            id: 10,
            type: "",
            effect: false,
          },
        ];
      } else if (window.innerWidth > 1800) {
        this.gallery = [
          {
            id: 1,
            type: "vertical",
            effect: false,
          },
          {
            id: 2,
            type: "",
            effect: false,
          },
          {
            id: 3,
            type: "",
            effect: false,
          },
          {
            id: 4,
            type: "",
            effect: false,
          },
          {
            id: 5,
            type: "",
            effect: false,
          },
          {
            id: 6,
            type: "",
            effect: false,
          },
          {
            id: 7,
            type: "",
            effect: false,
          },
          {
            id: 8,
            type: "",
            effect: false,
          },
          {
            id: 9,
            type: "",
            effect: false,
          },
          {
            id: 10,
            type: "horizontal",
            effect: false,
          },
        ];
      }
    },
    randomId: function () {
      this.copy = this.position;
      this.random = Math.ceil(Math.random() * 20);
      this.position = Math.ceil(Math.random() * 11);

      if (this.copy != this.random) {
        this.gallery[this.copy - 1].effect = true;

        setTimeout(() => {
          this.gallery[this.copy - 1].id = this.random;
        }, 500);
        setTimeout(() => {
          this.gallery[this.copy - 1].effect = false;
        }, 600);
      }
    },
    interval: function () {
      setInterval(this.randomId, 1500);
    },
  },
};
</script>

<style scoped>
:root {
  --shadow: rgba(0, 0, 0, 0.2);
}

.fadein {
  opacity: 0;
}

.fadeout {
  opacity: 1;
}

.content-gallery {
  background-color: #000;
}

.content-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;
  grid-auto-flow: dense;
}

.content-grid>div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

.content-grid>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 2px 16px var(--shadow);
  border-radius: 10px;
}

.horizontal {
  grid-column: span 2;
}

.vertical {
  grid-row: span 2;
}

.big {
  grid-column: span 2;
  grid-row: span 2;
}
</style>