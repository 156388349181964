<template>
  <div style="position: relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <banner-component bannerTitle="FRANCHISING" :bannerImage="bannerImage" />
    <description-layout />
    <why-layout />
    <design-layout />
    <process-layout />
    <ready-to-be-layout />
    <footer-component />
  </div>
</template>

<script>
import SlideNavbarComponent from '@/components/SlideNavbar.vue'
import SlideCartComponent from '@/components/SlideCart.vue'
import NavbarComponent from '@/components/Navbar.vue'
import FooterComponent from '@/components/Footer.vue'
import BannerComponent from '@/components/Banner.vue'
import DescriptionLayout from '../layouts/franchising/Description.vue'
import WhyLayout from '@/layouts/franchising/Why.vue'
import DesignLayout from '@/layouts/franchising/Design.vue'
import ProcessLayout from '@/layouts/franchising/Process.vue'
import ReadyToBeLayout from '@/layouts/franchising/ReadyToBe.vue'

export default {
  name: 'FranchisingView',
  data() {
    return {
      //variables
      bannerImage: `url(${require('@/assets/images/franchising/banner.png')})`,
    }
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    DescriptionLayout,
    WhyLayout,
    DesignLayout,
    ProcessLayout,
    ReadyToBeLayout,
    FooterComponent,
  },
}
</script>
