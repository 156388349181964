<template>
  <div class="bg-content">
    <v-container
      fluid
      class="p-left-container p-right-container"
    >
      <v-row>
        <v-col
          cols="11"
          md="12"
        >
          <div>
            <p class="txt-title po-regular">
              <span style="color: #c9da2d;">[</span>
              Ready to be your own jefe?
              <span style="color: #c9da2d;">]</span>
            </p>
            <div class="display-flex align-items-center justify-content-center">
              <p class="txt-description po-regular mb-0">
                Please fill in our LOZ TAKOS application form. Once we receive
                it,
              </p>
            </div>
            <div class="display-flex align-items-center justify-content-center">
              <p class="txt-description po-regular">
                our Franchise Director will be in touch with you.
              </p>
            </div>
            <div class="display-flex align-items-center justify-content-center mt-9 block-small">
              <div class="content-button mgn-r-5">
                <v-icon
                  class="mb-2"
                  color="#fff"
                  size="35px"
                >
                  mdi-tray-arrow-down
                </v-icon>
                <v-btn
                  @click="downloadPdf"
                  class="button-ready po-regular"
                >
                  Download our application
                </v-btn>
              </div>
              <div class="content-button mgn-l-5">
                <v-icon
                  class="mb-2"
                  color="#fff"
                  size="35px"
                >
                  mdi-information-outline
                </v-icon>
                <v-btn
                  @click="openModal = true"
                  class="button-ready po-regular"
                >
                  Request info
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <request-layout
      :openModal="openModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import RequestLayout from "./Request.vue";

export default {
  name: "ReadyToBeLayout",
  data() {
    return {
      //variables
      openModal: false,
    };
  },
  methods: {
    downloadPdf: function () {
      window.open(
        "https://loz-takos-images.s3.amazonaws.com/LOZ-TAKOS-FRANCHISE-APPLICATION-FORM-1.pdf",
        "blank"
      );
    },
    closeModal: function () {
      this.openModal = false;
    },
  },
  components: {
    RequestLayout,
  },
};
</script>

<style scoped>
.content-button {
  display: grid;
  text-align: center;
}

.button-ready {
  background-color: #dd4999 !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  text-transform: initial !important;
  color: #fff !important;
}

.bg-content {
  background-color: #191919;
  padding: 70px 0px;
}

.txt-title {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
}

.txt-description {
  text-align: center;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0px;
  -webkit-background-clip: text;
  background-image: linear-gradient(360deg, #dd4999 0%, #ffffff 100%);
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.mgn-r-5 {
  margin-right: 20px;
}

.mgn-l-5 {
  margin-left: 20px;
}

@media (max-width: 600px) {
  .block-small {
    display: block !important;
    text-align: center;
    text-align: -webkit-center;
  }

  .content-button {
    width: 90%;
    margin: 0;
    margin-bottom: 25px;
  }

  .txt-title {
    font-size: 30px;
  }

  .txt-description {
    font-size: 18px;
  }
}
</style>
