<template>
  <div class="bg-container content-bg-piedra">
    <img
      class="sombra"
      src="@/assets/images/about/sombra.png"
      alt=""
    />
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="content-text-design">
            <p class="txt-title po-bold mb-9">
              <span style="color: #c9da2d;">[</span>
              Design &
              <br />
              Construction
              <span style="color: #c9da2d;">]</span>
            </p>
            <p class="txt-description po-regular">
              We at LOZ TAKOS pay great attention to the guests visual
              experience, therefore the material selection, flow and design of
              each restaurant are some of the most important aspects we focus
              on.
              <br />
              <br />
              <br />
              Guided by a team of dedicated and eccentric designers all
              restaurants are carefully designed with concrete, stainless steel
              and wood , becoming the first truly Mexican restaurant chain to
              serve Mexican Street Food in a modern urban atmosphere.
            </p>
          </div>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          md="6"
        >
          <div class="content-image">
            <img
              src="@/assets/images/franchising/design.png"
              alt=""
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DesignLayout",
  data() {
    return {
      //variables
    };
  },
};
</script>

<style scoped>
.sombra {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
}

.bg-container {
  position: relative;
}

.content-text-design {
  padding: 50px;
  position: relative;
}

.txt-title {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-description {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-image {
  width: 100%;
  height: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
  }

  .content-text-design {
    padding: 20px !important;
  }
}
</style>
