<template>
  <div>
    <content-layout />
  </div>
</template>

<script>
import ContentLayout from "@/layouts/checkout/Content.vue";

export default {
  name: "CheckoutView",
  data() {
    return {
      //variables
    };
  },
  components: {
    ContentLayout,
  },
};
</script>