<template>
  <form
    class="bg-content"
    @submit.prevent="sendEmail"
  >
    <div class="content-logo display-flex align-items-center justify-content-flex-start">
      <img
        style="width: 100px"
        src="@/assets/images/checkout/logo.png"
        alt=""
      >
    </div>
    <div class="content-txt-screen">
      <p class="txt-screen po-regular">Checkout</p>
    </div>
    <div class="content-stepper">
      <v-stepper
        v-model="e6"
        vertical
        elevation="0"
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
          color="#DD4999"
          class="po-bold"
        >
          Location
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card
            elevation="0"
            class="card-content mb-5"
          >
            <v-container fluid>
              <v-row>
                <v-col>
                  <div>
                    <input
                      v-for="(item, index) in array"
                      :key="index"
                      type="text"
                      name="orden[]"
                      hidden
                      :value="`${item.title} - ${item.quantityCart} pieces`"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(item, index) in locations"
                  :key="index"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <input
                    hidden
                    type="radio"
                    name="ubicacion"
                    ref="rLocation"
                    :value="item.address"
                  />
                  <div
                    @click="radio(index)"
                    class="content-div-locations"
                    :style="(selectedLocation == index) ? {border: '2px solid #DD4999'} : {}"
                  >
                    <div class="content-image">
                      <img
                        :src="item.img"
                        alt=""
                      >
                    </div>
                    <div class="content-texts-locations">
                      <div class="display-flex align-items-center">
                        <p class="txt-location po-bold">Map pin Pickup</p>
                        <p class="item-location po-regular ml-2">{{ item.address }}</p>
                      </div>
                      <div class="display-flex align-items-center">
                        <p class="txt-location po-bold">Ready in about</p>
                        <p class="item-location po-regular ml-2">15-20 min</p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="display-flex align-items-center justify-content-flex-end mb-5">
            <v-btn
              @click="$router.push({name: 'Menu'})"
              elevation="0"
              class="button-back po-regular"
            >
              <v-icon
                size="14px"
                class="mr-1"
              >
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn
              :disabled="!validationLocation"
              elevation="0"
              class="button-next po-regular ml-2"
              @click="e6 = 2"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"
          color="#DD4999"
          class="po-bold"
        >
          Schedule
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card
            elevation="0"
            class="card-content mb-5"
          >
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div class="display-flex align-items-center">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <p class="txt-label po-regular mb-0">Select date</p>
                          <v-text-field
                            v-model="date"
                            name="fecha"
                            solo
                            suffix
                            readonly
                            hide-details
                            append-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            color="#dd4999"
                          ></v-text-field>
                        </div>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        color="#dd4999"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="#dd4999"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="#dd4999"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                    <div class="ml-5">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <p class="txt-label po-regular mb-0">Select time</p>
                            <v-text-field
                              v-model="time"
                              name="hora"
                              solo
                              suffix
                              readonly
                              hide-details
                              append-icon="mdi-clock"
                              v-bind="attrs"
                              v-on="on"
                              color="#dd4999"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="time"
                          full-width
                          @click:minute="$refs.menu2.save(time)"
                          color="#dd4999"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="display-flex align-items-center justify-content-flex-end mb-5">
            <v-btn
              @click="e6 = 1;"
              elevation="0"
              class="button-back po-regular"
            >
              <v-icon
                size="14px"
                class="mr-1"
              >
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn
              :disabled="!validateSchedule"
              elevation="0"
              class="button-next po-regular ml-2"
              @click="e6 = 3"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 3"
          step="3"
          color="#DD4999"
          class="po-bold"
        >
          Your details
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card
            elevation="0"
            class="card-content mb-5"
          >
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <div>
                      <p class="txt-label po-regular mb-0">Name</p>
                      <v-text-field
                        v-model="nombre"
                        name="nombre"
                        solo
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <div>
                      <p class="txt-label po-regular mb-0">Phone number</p>
                      <v-text-field
                        v-model="telefono"
                        name="telefono"
                        solo
                        hide-details
                        @keypress="soloNumbers"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <div>
                      <p class="txt-label po-regular mb-0">Preferences</p>
                      <v-textarea
                        v-model="preferencias"
                        name="preferencias"
                        solo
                        hide-details
                        rows="4"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="display-flex align-items-center justify-content-flex-end mb-5">
            <v-btn
              @click="e6 = 2"
              elevation="0"
              class="button-back po-regular"
            >
              <v-icon
                size="14px"
                class="mr-1"
              >
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn
              :disabled="!validateDetails"
              elevation="0"
              class="button-next po-regular ml-2"
              @click="e6 = 4"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step
          step="4"
          color="#DD4999"
          class="po-bold"
        >
          Confirmation
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card
            elevation="0"
            class="card-content mb-5"
          >
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <!-- mapa -->
                    <div>
                      <div class="content-div-locations">
                        <div class="content-image">
                          <img
                            :src="productSelected.img"
                            alt=""
                          >
                        </div>
                        <div class="content-texts-locations">
                          <div class="display-flex align-items-center">
                            <p class="txt-location po-bold">Map pin Pickup</p>
                            <p class="item-location po-regular ml-2">{{ productSelected.address }}</p>
                          </div>
                          <div class="display-flex align-items-center">
                            <p class="txt-location po-bold">Ready in about</p>
                            <p class="item-location po-regular ml-2">15-20 min</p>
                          </div>
                        </div>
                      </div>
                      <div class="display-flex align-items-center mt-2">
                        <p class="txt-location po-bold">Instructions</p>
                        <p class="item-location po-regular ml-2">Skip the line and head to the pickup counter. Tell the staff you’re here for your takeout order.</p>
                      </div>
                    </div>
                    <!-- horario -->
                    <div class="bordered">
                      <div class="display-flex align-items-center">
                        <div class="display-flex align-items-center mr-5">
                          <v-icon size="18px">
                            mdi-calendar
                          </v-icon>
                          <p class="txt-horarios po-regular mb-0 ml-1">{{ date }}</p>
                        </div>
                        <div class="display-flex align-items-center ml-5">
                          <v-icon size="18px">
                            mdi-clock
                          </v-icon>
                          <p class="txt-horarios po-regular mb-0 ml-1">{{ time }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- daatos -->
                    <div class="pt-5">
                      <div class="display-flex align-items-center">
                        <p
                          class="txt-horarios po-regular mb-0 mr-5"
                          style="width: 150px"
                        >Name</p>
                        <p class="txt-horarios po-regular mb-0 ml-5">{{ nombre }}</p>
                      </div>
                      <div class="display-flex align-items-center">
                        <p
                          class="txt-horarios po-regular mb-0 mr-5"
                          style="width: 150px"
                        >Phone number</p>
                        <p class="txt-horarios po-regular mb-0 ml-5">{{ telefono }}</p>
                      </div>
                      <div class="display-flex align-items-center">
                        <p
                          class="txt-horarios po-regular mb-0 mr-5"
                          style="width: 150px"
                        >Comments</p>
                        <p class="txt-horarios po-regular mb-0 ml-5">{{ preferencias }}</p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <div class="display-flex align-items-center justify-content-flex-end mb-5">
            <v-btn
              @click="e6 = 3;"
              elevation="0"
              class="button-back po-regular"
            >
              <v-icon
                size="14px"
                class="mr-1"
              >
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn
              type="submit"
              elevation="0"
              class="button-next po-regular ml-2"
              :loading="bLoading"
            >
              Send
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </div>
    <input
      type="text"
      name="total"
      :value="total"
      hidden
    >
  </form>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "StepperLayout",
  data() {
    return {
      //Variables
      total: 0,
      array: [],
      e6: 1,
      selectedLocation: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      nombre: "",
      telefono: "",
      preferencias: "",
      time: null,
      menu2: false,
      productSelected: {},
      locations: [
        {
          local: "COQUITLAM CENTER",
          address: "Unit 2658 – 2929 Barnet Hwy, Coquitlam, BC.",
          phone: "Tel. 604 475 30 00",
          img: [require("@/assets/images/checkout/1.png")],
        },
        /* {
          local: "WALNUT GROVE",
          address: "Unit 1A – 20349 88th Ave. Township of Langley, BC.",
          phone: "Tel. 604 371 22 74",
          img: [require("@/assets/images/checkout/2.png")],
        },
        {
          local: "BROADWAY",
          address: "792 West Broadway Vancouver, BC V5Z 1J5",
          phone: "Tel. 604 423 45 25",
          img: [require("@/assets/images/checkout/3.png")],
        },
        {
          local: "ABBOTSFORD",
          address: "Unit 402- 2140 Sumas Way Abbotsford, BC V2S2C7",
          phone: "Tel. 604 746 6080",
          img: [require("@/assets/images/checkout/4.png")],
        }, */
      ],
      bLoading: false,
    };
  },
  beforeMount() {
    this.array = this.$store.state.checkoutArr;
    this.getTotal();
  },
  methods: {
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    sendEmail: function (e) {
      this.bLoading = true;
      const self = this;

      emailjs
        .sendForm(
          "service_nhbz5lo",
          "template_yj3w46s",
          e.target,
          "user_PIpQsjbgK2NtqIL9VXeoQ"
        )
        .then(
          (result) => {
            this.bLoading = false;

            this.$swal({
              title: "Success",
              text: "The email has been sent successfully!",
              type: "success",
            }).then(function () {
              self.$store.commit("setCheckout", []);
              self.$router.push({
                name: "Menu",
              });
            });

            this.selectedLocation = null;
            this.nombre = "";
            this.telefono = "";
            this.preferencias = "";
            this.productSelected = {};
            this.$store.commit("setProductsCartArr", []);
            this.$store.commit("setCheckout", []);
          },
          (error) => {
            this.bLoading = false;

            // this.e6 = 1;
            this.$swal({
              title: "Success",
              text: "There are problems sending the email",
              type: "error",
            }).then(function () {
              window.reload();
            });

            this.selectedLocation = null;
            this.nombre = "";
            this.telefono = "";
            this.preferencias = "";
            this.productSelected = {};
          }
        );
    },
    radio: function (idx) {
      this.$refs.rLocation[idx].click();
      this.selectedLocation = idx;
      this.productSelected = this.locations[idx];
    },
    getTotal: function () {
      var total = 0;
      for (var i in this.array) {
        total += this.array[i].subtotal;
      }
      this.total = total.toFixed(2);
    },
  },
  watch: {},
  computed: {
    validationLocation: function () {
      return this.selectedLocation != null;
    },
    validateSchedule: function () {
      return (
        this.time !== "" &&
        this.time !== null &&
        this.menu !== "" &&
        this.menu !== null
      );
    },
    validateDetails: function () {
      return (
        this.nombre !== "" && this.telefono !== "" && this.preferencias !== ""
      );
    },
  },
};
</script>

<style>
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  color: #dd4999 !important;
  text-shadow: none !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: #dd4999 !important;
  text-shadow: none !important;
}
</style>

<style scoped>
.bordered {
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  padding: 20px 0px;
}

.txt-horarios {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-location {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
}

.item-location {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-texts-locations {
  padding: 15px 10px;
}

.content-div-locations {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  opacity: 1;
}

.content-image {
  width: 100%;
  height: 150px;
}

.content-image img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.card-content {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 20px;
}

.v-application--is-ltr
  .theme--light.v-stepper--vertical
  .v-stepper__content:not(:last-child) {
  border-left: 1px solid #dd4999;
}

.button-next {
  border: 1px solid #dd4999 !important;
  border-radius: 25px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  background-color: #dd4999 !important;
  text-transform: initial;
  width: 100px;
}

.button-next:disabled {
  cursor: no-drop;
  pointer-events: all;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.button-back {
  border: 1px solid #9d9d9c !important;
  border-radius: 25px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9d9d9c;
  background-color: #fff !important;
  text-transform: initial;
  width: 100px;
}

.bg-content {
  height: 100vh;
  overflow: auto;
}

.content-txt-screen {
  padding: 20px;
}

.content-logo {
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
}

.txt-screen {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
</style>