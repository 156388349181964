<template>
  <div
    id="content-faq-section"
    class="content-faq position-relative"
  >
    <!-- <div class="content-image">
      <img src="@/assets/images/faq/chiles.png" alt="" />
    </div>
    <div class="content-chile1">
      <img src="@/assets/images/faq/chile1.png" alt="" />
    </div>
    <div class="content-chile2">
      <img src="@/assets/images/faq/chile2.png" alt="" />
    </div>
    <div class="content-chile3">
      <img src="@/assets/images/faq/chile3.png" alt="" />
    </div> -->
    <v-container
      fluid
      class="v-container p-left-container p-right-container"
    >
      <v-row>
        <v-col>
          <div class="display-flex align-items-center justify-content-center">
            <div>
              <p class="txt-title po-regular">FAQ</p>
              <p class="txt-description po-regular">
                Got a question? We're to answer!
              </p>
              <div class="display-flex align-items-center justify-content-center">
                <v-btn
                  @click="redirect('Faq')"
                  class="button po-medium"
                >
                  Go to FAQ
                  <v-icon
                    class="ml-1"
                    color="#fff"
                    size="15px"
                  >
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FaqComponent",
  data() {
    return {
      //variables
    };
  },
  methods: {
    redirect: function (res) {
      this.$router.push({ name: res });
    },
  },
};
</script>

<style scoped>
.content-chile1 {
  position: absolute;
  right: 0;
  top: -30px;
  width: 250px;
}

.content-chile1 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-chile1 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-chile2 {
  position: absolute;
  right: -140px;
  top: -100px;
  width: 350px;
}

.content-chile2 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-chile2 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-chile3 {
  position: absolute;
  right: -10px;
  bottom: -145px;
  width: 350px;
}

.content-chile3 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-chile3 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-image {
  position: absolute;
  left: -210px;
  top: 0px;
  width: 500px;
  height: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.txt-title {
  text-align: center;
  color: #fff;
  font-size: 50px;
  letter-spacing: 0px;
}

.txt-description {
  text-align: center;
  color: #fff;
  font-size: 30px;
  letter-spacing: 0px;
  -webkit-background-clip: text;
  background-image: linear-gradient(360deg, #ffffff 0%, #dd4999 100%);
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.button {
  width: fit-content;
  background: #dd4999 0% 0% no-repeat padding-box !important;
  border-radius: 30px;
  text-align: left;
  font-size: 16px !important;
  text-transform: initial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0px 25px !important;
}

.content-faq {
  background-color: #191919;
  padding: 70px 0px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .txt-title {
    font-size: 40px;
  }

  .txt-description {
    font-size: 20px;
  }

  .button {
    font-size: 16px !important;
  }
}

@media (max-width: 600px) {
  .txt-description {
    font-size: 16px;
  }

  .content-chile1 {
    width: 200px;
  }

  .content-chile2 {
    width: 300px;
  }

  .content-chile3 {
    width: 300px;
  }

  .content-image {
    left: -250px;
    top: 50px;
    width: 400px;
  }
}
</style>
