<template>
  <div class="content-slide-cart">
    <div class="display-flex align-items-center full-width full-height">
      <div class="content-cart">
        <!--Start: Content HTML -->
        <div class="content-txt-cart display-flex align-items-center full-height">
          <p class="txt-cart po-regular">Cart</p>
        </div>
        <div
          class="content-cards"
          :style="{ height: `${screenHeight}px` }"
        >
          <div
            v-for="(item, index) in array"
            :key="index"
            class="card display-flex align-items-center"
          >
            <div
              :style="{ backgroundImage: `${item.background}` }"
              class="card-image"
            >
            </div>
            <div class="ml-2 full-width">
              <p class="card-title po-bold mb-0">{{ item.title }}</p>
              <div class="display-flex align-items-center">
                <p class="card-price-txt po-regular mb-0">Price</p>
                <p class="card-price-val po-regular mb-0 ml-2">${{ item.unitPrice.toFixed(2) }} CAD</p>
              </div>
              <div class="display-flex align-items-center">
                <p class="card-subtotal-txt po-regular mb-0">Subtotal</p>
                <p class="card-subtotal-val po-bold mb-0 ml-2">${{ item.subtotal.toFixed(2) }} CAD</p>
              </div>
            </div>
            <div class="display-flex align-items-flex-end justify-content-flex-end full-height">
              <p class="card-subtotal-txt">{{ item.quantityCart }}</p>
            </div>
          </div>
        </div>
        <div class="content-txt-total display-flex align-items-center justify-content-center full-height">
          <p class="txt-total po-regular">TOTAL</p>
          <p class="txt-total po-regular ml-5">${{ total.toFixed(2) }} CAD</p>
        </div>
        <!--End: Content HTML -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderLayout",
  data() {
    return {
      //variables
      array: [],
      screenHeight: 0,
      total: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.array = this.arr;
    this.getTotal();
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 140;
    },
    getTotal: function () {
      var total = 0;
      for (var i in this.array) {
        total += this.array[i].subtotal;
      }
      this.total = total;
    },
  },
  computed: {
    arr: function () {
      return this.$store.state.checkoutArr;
    },
  },
};
</script>

<style scoped>
.card-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #c9da2d;
  opacity: 1;
}

.card-price-txt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-price-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-subtotal-txt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-price-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-subtotal-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.card {
  background-color: #191919;
  border-radius: 0px;
  padding: 20px 10px;
  border-bottom: 1px solid #4b4b4b;
  position: relative;
}

.card-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 60px;
  width: 100px;
  border-radius: 10px;
}

.txt-cart {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.txt-total {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.content-txt-cart {
  background-color: #000;
  height: 70px;
  width: 100%;
  padding: 10px;
}

.content-cards {
  padding: 10px;
  background-color: #191919;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 70px;
}

/* width */
.content-cards::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.content-cards::-webkit-scrollbar-track {
  background: #707070;
  border-radius: 10px;
}

/* Handle */
.content-cards::-webkit-scrollbar-thumb {
  background: #c9da2d;
}

/* Handle on hover */
.content-cards::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

.content-txt-total {
  padding: 10px;
  background-color: #000;
  height: 70px;
  width: 100%;
}

.content-cart {
  height: 100%;
  width: 450px;
  background-color: #000;
  position: relative;
}

.content-slide-cart {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>