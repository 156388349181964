<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      //variables
    };
  },
};
</script>

<style>
* {
  transition: all 0.2s linear;
}
.mgl-map-wrapper {
  height: 400px !important;
  position: relative !important;
  width: 100% !important;
}

html,
body {
  margin: 0;
  font-family: "inter-light", sans-serif;
  position: relative;
  font-size: 100%;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c9da2d;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dd4999;
}

/* import fonts */
@font-face {
  font-family: "poppins-black";
  src: url("../src/assets/fonts/Poppins-Black.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-bold";
  src: url("../src/assets/fonts/Poppins-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-extrabold";
  src: url("../src/assets/fonts/Poppins-ExtraBold.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-extralight";
  src: url("../src/assets/fonts/Poppins-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-italic";
  src: url("../src/assets/fonts/Poppins-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-light";
  src: url("../src/assets/fonts/Poppins-Light.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-medium";
  src: url("../src/assets/fonts/Poppins-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-regular";
  src: url("../src/assets/fonts/Poppins-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../src/assets/fonts/Poppins-SemiBold.ttf") format("opentype");
}

.po-black {
  font-family: "poppins-black", sans-serif;
}

.po-bold {
  font-family: "poppins-bold", sans-serif;
}

.po-extrabold {
  font-family: "poppins-extrabold", sans-serif;
}

.po-extralight {
  font-family: "poppins-extralight", sans-serif;
}

.po-italic {
  font-family: "poppins-italic", sans-serif;
}

.po-light {
  font-family: "poppins-light", sans-serif;
}

.po-medium {
  font-family: "poppins-medium", sans-serif;
}

.po-regular {
  font-family: "poppins-regular", sans-serif;
}

.po-semibold {
  font-family: "poppins-semibold", sans-serif;
}

/* global classes */
.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.position-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.txt-title-section {
  text-align: left;
  font-size: 70px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
  font-weight: 600;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.v-container {
  width: 100% !important;
}

.p-left-container {
  padding-left: 50px !important;
}

.p-right-container {
  padding-right: 50px !important;
}
@media (max-width: 430px) {
  .txt-title-section {
    font-size: 1.7em !important;
  }

}
@media (max-width: 600px) {
  .p-left-container {
    padding-left: 12px !important;
  }

  .p-right-container {
    padding-right: 12px !important;
  }

  .txt-title-section {
    font-size: 2rem;
  }
}
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .txt-title-section {
    font-size: 2em !important;
  }
}
@media (min-width: 960px) and (max-width: 1264px) {
  .txt-title-section {
    font-size: 3em !important;
  }
}

/* bg piedra */
.content-bg-piedra {
  background-repeat: repeat;
  background-image: url("./assets/images/piedra.jpeg");
  background-attachment: scroll;
  background-position: top left;
}

.swal2-styled.swal2-confirm {
  background-color: #dd4999 !important;
}

.swal2-icon.swal2-info {
  border-color: #dd4999 !important;
  color: #dd4999 !important;
}
</style>