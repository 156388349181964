<template>
  <div style="position: relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <carousel-layout />
  </div>
</template>

<script>
import SlideNavbarComponent from '@/components/SlideNavbar.vue'
import SlideCartComponent from '@/components/SlideCart.vue'
import NavbarComponent from '@/components/Navbar.vue'
import CarouselLayout from '@/layouts/home/Carousel.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      //variables
    }
  },
  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    CarouselLayout,
  },
}
</script>
