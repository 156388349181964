<template>
  <div class="content-bg-piedra content-drill display-flex align-items-center justify-content-center full-height">
    <div class="content-image">
      <img
        src="@/assets/images/about/sombra.png"
        alt=""
      >
    </div>
    <v-container
      fluid
      class="p-left-container p-right-container"
    >
      <v-row>
        <v-col>
          <div>
            <p class="txt-title po-bold">
              <span style="color: #C9DA2D;">[</span>
              The Loz Takos Experience
              <span style="color: #C9DA2D;">]</span>
            </p>
            <p class="txt-description po-bold">Its Simple.</p>
            <p class="txt-description po-bold">Tell us all about your tako dreams, and we will make then happen.</p>
            <p class="txt-description po-bold">The complete experience includes and is not limited to:</p>
            <p class="txt-description po-bold"> - Drop Off Services.</p>
            <p class="txt-description po-bold"> - Full-Service Authentic Catering.</p>
            <p class="txt-description po-bold"> - Fundraising Opportunities Available.</p>
            <p class="txt-description po-bold"> - School Hot Lunch Program.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DrillLayout",
  data() {
    return {
      //variables
    };
  },
};
</script>

<style scoped>
.txt-title {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.txt-description {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-drill {
  position: relative;
  padding: 150px 0px;
  overflow: hidden;
}

.content-image {
  position: absolute;
  left: -0;
  top: -15px;
  width: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 35px;
  }

  .txt-description {
    font-size: 16px;
  }
}
</style>