import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Catering from "../views/Catering.vue";
import Gallery from "../views/Gallery.vue";
import Faq from "../views/Faq.vue";
import Contact from "../views/Contact.vue";
import Order from "../views/Order.vue";
import Franchising from "../views/Franchising.vue";
import Checkout from "../views/Checkout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/catering",
    name: "Catering",
    component: Catering,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/Menu",
    name: "Menu",
    component: Order,
  },
  {
    path: "/franchising",
    name: "Franchising",
    component: Franchising,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
});

export default router;
