<template>
  <div class="bg-content">
    <v-container
      fluid
      class="p-left-container p-right-container"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="mb-9">
            <p class="txt-title po-regular">WHY CHOOSE <br> LOZ TAKOS FRANCHISE</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) in array"
          :key="index"
          cols="12"
          md="3"
        >
          <div class="content-why">
            <div class="display-flex align-items-center justify-content-center mb-1">
              <img
                width="80px"
                :src="item.img"
                alt=""
              >
            </div>
            <div class="display-flex align-items-center justify-content-center">
              <p
                class="why-text po-regular"
                v-html="item.text"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WhyLayout",
  data() {
    return {
      //variables
      array: [
        {
          text: "Profitable business system",
          img: [require("@/assets/images/franchising/1.png")],
        },
        {
          text: "Extensive site selection program",
          img: [require("@/assets/images/franchising/2.png")],
        },
        {
          text: "Site lay outs & renderings",
          img: [require("@/assets/images/franchising/3.png")],
        },
        {
          text: "Training program and seminars",
          img: [require("@/assets/images/franchising/4.png")],
        },
        {
          text: "Comprehensive operational handbooks",
          img: [require("@/assets/images/franchising/5.png")],
        },
        {
          text: "Operational tools",
          img: [require("@/assets/images/franchising/6.png")],
        },
        {
          text: "Marketing catalogue and marketing programs (L.S.M)",
          img: [require("@/assets/images/franchising/7.png")],
        },
        {
          text: "Ongoing technical support and assistance",
          img: [require("@/assets/images/franchising/8.png")],
        },
        {
          text: "Authentic mexican real street food, just the way you love it!",
          img: [require("@/assets/images/franchising/9.png")],
        },
        {
          text: "Core menus and implementation of Seasonal Products",
          img: [require("@/assets/images/franchising/10.png")],
        },
        {
          text: "Different business concepts adaptable to your local market",
          img: [require("@/assets/images/franchising/11.png")],
        },
      ],
    };
  },
};
</script>

<style scoped>
.why-text {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-why {
  background: transparent;
  box-shadow: 0px 0px 60px transparent;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 50px 0px;
}

.content-why:hover {
  background: #dd4999;
  box-shadow: 0px 0px 60px #dd4999;
  cursor: default;
}

.txt-title {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.bg-content {
  background-color: #000;
  padding: 70px 20px;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 30px;
  }
}
</style>