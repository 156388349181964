<template>
  <div style="height: 100%; position: relative">
    <div
      class="banner-content"
      :style="{height: `${screenHeight}px`}"
    >
      <banner-layout />
    </div>
    <!-- <div class="display-flex align-items-center">
      <v-carousel
        v-model="carouselOne"
        :vertical="true"
        :height="screenHeight"
        :continuous="true"
        :show-arrows="false"
        :hide-delimiters="true"
        :interval="2000"
        :cycle="true"
      >
        <v-carousel-item v-for="(item, index) in images" :key="index">
          <div class="content-image">
            <div class="bg-content-image" />
            <img :src="item.source" alt="" />
          </div>
        </v-carousel-item>
      </v-carousel>
      <v-carousel
        v-model="carouselTwo"
        :vertical="true"
        :height="screenHeight"
        :continuous="true"
        :show-arrows="false"
        :hide-delimiters="true"
        :interval="2000"
        :cycle="true"
        :reverse="true"
      >
        <v-carousel-item v-for="(item, index) in images" :key="index">
          <div class="content-image">
            <div class="bg-content-image" />
            <img :src="item.source" alt="" />
          </div>
        </v-carousel-item>
      </v-carousel>
      <v-carousel
        v-model="carouselThree"
        :vertical="true"
        :height="screenHeight"
        :continuous="true"
        :show-arrows="false"
        :hide-delimiters="true"
        :interval="2000"
        :cycle="true"
      >
        <v-carousel-item v-for="(item, index) in images" :key="index">
          <div class="content-image">
            <div class="bg-content-image" />
            <img :src="item.source" alt="" />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div> -->
  </div>
</template>

<script>
import BannerLayout from "@/layouts/home/Banner.vue";

export default {
  name: "CarouselLayout",
  data() {
    return {
      //variables
      screenHeight: 0,
      carouselOne: 1,
      carouselTwo: 2,
      carouselThree: 3,
      images: [
        {
          source: [require("@/assets/images/home/carousel1.png")],
        },
        {
          source: [require("@/assets/images/home/carousel2.png")],
        },
        {
          source: [require("@/assets/images/home/carousel3.png")],
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.screenHeight = window.innerHeight + 70;
      } else {
        this.screenHeight = window.innerHeight - 70;
      }
    },
  },
  components: {
    BannerLayout,
  },
};
</script>

<style scoped>
.banner-content {
  background: url("../../assets/images/home/fondoInicial.jpg");
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-top: 70px; */
  /* filter: grayscale(1); */
}

.bg-content-image {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.content-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>
