<template>
  <div class="content-bg-piedra">
    <v-container fluid>
      <v-row>
        <v-col
          class="pa-0"
          cols="12"
          md="6"
        >
          <div
            v-for="(item, index) in array"
            :key="index"
          >
            <div
              @click="answer(item.id)"
              :class="(selected == item.id) ? 'content-question display-flex align-items-center active' : 'content-question display-flex align-items-center'"
            >
              <p class="text-question po-regular">{{ item.question }}</p>
            </div>
          </div>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          md="6"
        >
          <div class="content-answer full-height full-width position-relative">
            <!-- <img
              class="aguacates"
              src="@/assets/images/faq/aguacates.png"
              alt=""
            > -->
            <div id="content-fixed">
              <p class="text-answer-title po-bold">{{ array[selected-1].id }}.</p>
              <p class="text-answer-description po-regular">{{ array[selected-1].answer }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FaqLayout",
  data() {
    return {
      //variables
      array: [
        {
          id: 1,
          question: "All franchise agreement costs are the same?",
          answer:
            "The cost of our franchises varies according to the type of agreement. Please contact us for more detailed information about our franchise cost.",
        },
        {
          id: 2,
          question:
            "All development costs are the same or there is a range of development cost?",
          answer:
            "The range of development cost is due primarily to varying site conditions and square footage. Many locations available have previously been used as restaurant sites and have the necessary infrastructure in place such as plumbing, electrical service, drop ceilings, etc. This existing infrastructure reduces development cost.",
        },
        {
          id: 3,
          question: "Does LOZ TAKOS offer direct financing?",
          answer: "We do not offer direct financing.",
        },
        {
          id: 4,
          question:
            "Why does LOZ TAKOS prefer “significant restaurant management experience” of franchise organizations?",
          answer:
            "Why does LOZ TAKOS prefer “significant restaurant management experience” of franchise organizations?",
          answer:
            "Franchisees identify proposed locations and submit their requests to our head office for approval. Why does LOZ TAKOS prefer “significant restaurant management experience” of franchise organizations? Any restaurant entity requires minute attention to detail, which is not obvious to inexperienced operators. LOZ TAKOS is no exception. Restaurant experience or if you have experience running another type of business including working with employees and customers, managing cash and generating and reviewing financial statements, such experience is always helpful.",
        },
        {
          id: 5,
          question: "Does LOZ TAKOS provide training?",
          answer:
            "LOZ TAKOS has a top-quality training program, which combines technical and behavioral education. After signing the FDA you and your group will receive a full seminar training and before the opening, you, your partners and staff will get a full opening support from 8 to 15 days prior the opening and full support during the life of the franchise agreement; personal visits, store reviews and feedback, open line with our head office, Skype conferences, franchise seminars and much more.",
        },
        {
          id: 6,
          question: "Does LOZ TAKOS offer multi-unit franchise opportunities?",
          answer:
            "Yes, all our franchise concepts offer both single and multi-unit franchises.",
        },
        {
          id: 7,
          question: "How much can I expect to earn?",
          answer:
            "Your earnings capability will depend on a number of factors, such as your location, the amount of time you spend in your store, your business and retail acumen, as well as market conditions. Naturally, as in any business venture, there is no guarantee of your success or profitability. We do however give you the benefit of a well-organized system and a concept refined by our many years of experience. What is the term of the franchising agreement? The initial term is ten years, after which the agreement may be renewed for additional ten-year terms. Renewal is subject to our approval, current franchise terms and conditions, and renewal fees, which are less than the initial franchise fee.",
        },
        {
          id: 8,
          question: "What is the term of the franchising agreement?",
          answer:
            "Royalties fees of 3% the first year, 4% the second and 5% of the third year and up. You are also required to spend 1.5% to 2.5% of net sales on local advertising. These fees may vary according to the type of franchise.",
        },
        {
          id: 9,
          question: "Can I sell my franchise?",
          answer:
            "Yes, like any other business, you can sell your restaurant to a buyer approved by the company. There is a transfer fee for administrative, legal and training expenses to affect the transfer. The information in this website is not a franchise offering and it is not directed to the residents of any particular jurisdiction. No offer or sale of a restaurant franchise will be made except by a Franchise Disclosure Document (FDD), first filed and registered with applicable state authorities. Any information collected on this is subject to our Privacy Policy.",
        },
        {
          id: 10,
          question: "Does LOZ TAKOS concept include a royalty fee?",
          answer:
            "The initial franchise fee is charged according to the FDA what the cost per restaurant is of CAN$ 24,000",
        },
        {
          id: 11,
          question: "LOZ TAKOS charge an initial franchise fee?",
          answer:
            "We do not charge anything for Marketing, but according to the FDA, the franchise must spend 1% of net sales on its LSM programs, which will be auditable.",
        },
      ],
      selected: 1,
    };
  },
  created() {
    window.addEventListener("scroll", this.fixed);
    this.fixed();
  },
  destroyed() {
    window.removeEventListener("scroll", this.fixed);
  },
  methods: {
    answer: function (id) {
      this.selected = id;
    },
    fixed: function (e) {
      if (e != undefined) {
        let fixed_div = document.getElementById("content-fixed");
        let about =
          document.getElementById("content-banner").offsetTop - window.scrollY;
        let products =
          document.getElementById("footer").offsetTop - window.scrollY;
        let restant = products - 1000;
        let y = 0;

        if ((about < 0) & (restant > 0)) {
          y = -about - 450;
          fixed_div.style.transform = `translateY(${y}px)`;
        } else if (window.innerWidth <= 600) {
          fixed_div.style.transform = "";
        }
      }
    },
  },
};
</script>

<style scoped>
.aguacates {
  position: absolute;
  bottom: -50px;
  right: -48px;
  width: 150px;
}

.active {
  background-color: #dd4999;
}

.active p {
  color: #fff;
}

.text-answer-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #c9da2d;
  opacity: 1;
}

.text-answer-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.content-answer {
  padding-left: 60px;
  overflow: hidden;
  padding-right: 40px;
}

.content-question {
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  height: 130px;
  padding-left: 50px;
}

.content-question:hover {
  background-color: #ffffff20;
  cursor: pointer;
}

.content-question:hover p {
  color: #000;
}

.text-question {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}
#content-fixed {
    padding-bottom: 30px;
  }

@media (max-width: 600px) {
  #content-fixed {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .text-question {
    font-size: 16px;
  }

  .content-question, .content-answer {
    padding: 20px;
  }

  .text-answer-description {
    font-size: 18px;
  }
}
</style>