<template>
  <div class="bg-content">
    <div class="content-tab p-left-container p-right-container">
      <v-tabs v-model="selectedTab" background-color="transparent" color="white" align-with-title fixed-tabs show-arrows
        dark>
        <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
        <v-tab @click="tab(item.id)" v-for="(item, index) in array" :key="index" class="po-regular title-align">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </div>
    <div>
      <v-tabs-items v-model="selectedTab" class="black">
        <v-tab-item v-for="(item, index) in array" :key="index" class="content-tab-item">
          <div class="content-banner" :style="{ backgroundImage: `${item.background}` }" />
          <v-container>
            <v-row>
              <v-col class="pb-0" cols="12" md="12">
                <div class="display-flex align-items-center justify-content-center">
                  <div class="title-section">
                    <p class="po-bold">{{ item.title }}</p>
                  </div>
                </div>
              </v-col>
              <v-col v-if="item.description !== '---'" cols="12" md="12">
                <div class="display-flex align-items-center justify-content-center">
                  <div class="description-section">
                    <p class="po-bold">{{ item.description }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="(item_product, index_product) in item.list" :key="index_product" cols="12" md="4">
                <div
                  :class="(!item_product.vegan) ? 'content-list-background display-flex align-items-flex-end' : 'content-list-background-vegan display-flex align-items-flex-end'"
                  :style="{ backgroundImage: item_product.background }">
                  <div v-if="item_product.vegan" class="flag-vegan po-bold">
                    Veggie option
                  </div>
                  <div>
                    <div class="list-content-text">
                      <p class="list-title po-bold mb-0">{{ item_product.title }}</p>
                      <p class="list-price po-regular mb-0">${{ item_product.price.toFixed(2) }} CAD</p>
                    </div>
                    <div class="overlay"></div>
                    <div class="list-content-actions display-flex align-items-center">
                      <v-btn @click="addOrder(item_product, index_product); addCart(item_product, index_product);"
                        class="button-add po-regular">
                        Add to cart
                      </v-btn>
                      <v-spacer />
                      <input :value="item_product.quantity" ref="inputs" type="text" class="list-input po-regular"
                        @keypress="soloNumbers" @keyup="ajustAmount($event, index_product)"
                        @blur="noEmpty(index_product)" maxlength="2">
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import menu from "./OptionsTabs.js";

export default {
  name: "TabsLayout",
  data() {
    return {
      //variables
      selectedTab: 0,
      array: [],
      hoverButton: false,
      productsArr: [],
      productsCart: [],
    };
  },
  beforeMount() {
    this.array = menu;
  },
  methods: {
    addCart: function (order, index) {
      this.productsCart = this.$store.state.productsCartArr;
      this.productsCart.push({
        id: order.id,
        background: order.background,
        title: order.title,
        price: order.price,
        vegan: order.vegan,
        quantity: this.$refs.inputs[index].value,
        folio: order.folio,
      });
      this.$refs.inputs[index].value = 1;
      this.$store.commit("setProductsCartArr", this.productsCart);
    },
    addOrder: function (order, index) {
      this.productsArr.push({
        ...order,
        quantity: this.$refs.inputs[index].value,
      });
      this.$store.commit("setAlertOrder", {
        active: true,
        arr: this.productsArr,
      });
      // this.$refs.inputs[index].value = 1;
      setTimeout(() => {
        this.productsArr.shift();
        this.$store.commit("setAlertOrder", {
          active: true,
          arr: this.productsArr,
        });
      }, 4000);
    },
    tab: function (id) {
      this.selectedTab = id;
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    ajustAmount: function (evt, index) {
      if (this.$refs.inputs[index].value == "00") {
        this.$refs.inputs[index].value = 1;
      } else if (this.$refs.inputs[index].value == "0") {
        this.$refs.inputs[index].value = 1;
      } else {
        this.$refs.inputs[index].value = evt.target.value;
      }
    },
    noEmpty: function (index) {
      if (this.$refs.inputs[index].value == "") {
        this.$refs.inputs[index].value = 1;
      }
    },
  },
};
</script>

<style>
.content-tab .v-slide-group__wrapper {
  padding-bottom: 5px;
}
</style>

<style scoped>
.list-content-actions {
  position: absolute;
  width: 100%;
  left: 0;
  top: 230px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  padding: 10px !important;
}

.list-content-text {
  position: absolute;
  left: 0;
  top: 215px;
  z-index: 1;
  transition: top 0.5s ease;
}

.content-list-background:hover .list-content-text {
  top: 150px;
}

.content-list-background:hover div,
.content-list-background-vegan:hover div {
  background: transparent;
}

.content-list-background:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.content-list-background:hover .list-content-actions {
  opacity: 1;
}

.content-list-background-vegan:hover .list-content-text {
  top: 150px;
}

.content-list-background-vegan:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.content-list-background-vegan:hover .list-content-actions {
  opacity: 1;
}

.flag-vegan {
  background: #c9da2d !important;
  color: #dd4999;
  font-size: 12px;
  width: fit-content !important;
  height: fit-content !important;
  padding: 8px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
  border-radius: 10px;
}

.content-list-background {
  height: 300px;
  border-radius: 10px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  border: 1px solid #000;
}

.content-list-background-vegan {
  height: 300px;
  border-radius: 10px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  border: 1px solid #c9da2d;
}

.content-banner {
  position: absolute;
  height: 60vh;
  width: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  z-index: 0;
}

.list-input {
  height: 40px;
  width: 70px !important;
  color: #fff;
  font-size: 14px;
  background-color: #ffffff20;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  text-align: center;
}

.button-add {
  background-color: #dd4999 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  text-transform: initial !important;
  color: #fff !important;
}

.title-section {
  background-color: #00000090;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  width: fit-content;
  padding: 5px 15px;
}

.title-section p {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 5px;
  margin-bottom: 0;
}

.description-section {
  background-color: #00000090;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  width: fit-content;
  padding: 5px 15px;
}

.description-section p {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.list-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.list-price {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-list-background div {
  /* background: rgb(0, 0, 0); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, transparent 100%);
  width: 100%;
  padding: 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content-list-background-vegan div {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, transparent 100%);
  width: 100%;
  padding: 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content-tab-item {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px 0px;
}

.separator-line-tab {
  background-color: #dd4999;
  height: 5px;
  margin-left: 2px;
  border-radius: 10px;
}

.content-tab .v-tab {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.5;
  text-transform: initial;
}

.content-tab .v-tab--active {
  opacity: 1;
}

.bg-content {
  background-color: #000;
}

.title-align {
  white-space: nowrap !important;
  margin: 5px 8px !important;
}
@media (max-width: 600px) {
  .title-align {
  white-space: nowrap !important;
  margin: 5px 8px !important;
  font-size: 14px !important;
}}
</style>