<template>
  <div class="display-flex">
    <div class="content-left">
      <stepper-layout />
    </div>
    <div class="content-right">
      <order-layout />
    </div>
  </div>
</template>

<script>
import OrderLayout from "@/layouts/checkout/Order.vue";
import StepperLayout from "@/layouts/checkout/Stepper.vue";

export default {
  name: "ContentLayout",
  data() {
    return {
      //variables
    };
  },
  components: {
    OrderLayout,
    StepperLayout,
  },
};
</script>

<style scoped>
.content-left {
  width: calc(100% - 450px);
}

.content-right {
  width: 450px;
}
</style>