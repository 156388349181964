<template>
  <div class="display-flex align-items-center justify-content-center container-logo">
    <div>
      <div class="center-mobile">
        <div class="content-logo">
          <img
            src="@/assets/images/logoBlack.png"
            alt=""
          />
        </div>
      </div>
      <p class="txt-title po-semibold mt-9">
        " <span style="color: #fff;"> We don't make fast food.</span> <br /> <span style="color:#fff;">we make real</span> <span style="color: #dd4999;">mexican</span> <span style="color: #fff;">street food</span> <br /> <span style="color: #fff;">as fast as we can.</span> "
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerLayout",
  data() {
    return {
      //variables
    };
  },
};
</script>

<style scoped>
.green {
  color: #c9da2b;
}

.pink {
  color: #dd4999;
}

.txt-title {
  color: #c9da2b;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
}

.container-logo {
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.content-logo {
  width: 60vw;
}

.content-logo img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-logo img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .center-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .txt-title {
    font-size: 18px;
  }
}
</style>