<template>
  <transition name="fade">
    <div
      class="content-global-notification"
      v-if="active"
    >
      <div
        class="content-notification mb-7"
        v-for="(item, index) in arr"
        :key="index"
      >
        <div class="display-flex align-items-center">
          <div
            class="content-image"
            :style="{ backgroundImage: `${item.background}` }"
          />
          <div class="display-flex align-items-center full-width full-height">
            <div class="pl-2 pr-2 full-width full-height">
              <p class="txt-title po-bold mb-0">{{ item.title }}</p>
              <p class="txt-description po-regular mb-0">${{ item.price }} CAD</p>
            </div>
            <div class="display-flex align-items-center justify-content-flex-end full-width full-height">
              <p class="txt-pieces po-regular mb-0">{{ item.quantity > 1 ?  ' pieces' : 'piece' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertOrderComponent",
  data() {
    return {
      //variablesƒ
    };
  },
  computed: {
    active: function () {
      return this.$store.state.alertOrderActive;
    },
    arr: function () {
      return this.$store.state.alertOrderArr;
    },
  },
  watch: {},
};
</script>

<style scoped>
.txt-pieces {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.txt-description {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-image {
  background-size: cover;
  width: 100px;
  height: 70px;
  border-radius: 5px;
}

.content-global-notification {
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 4;
  max-height: 500px;
  /* background-color: red; */
  overflow-y: auto;
  padding: 10px;
}

.content-global-notification::-webkit-scrollbar {
  height: 5px;
  width: 0px;
}

.content-global-notification::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-global-notification::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}

.content-global-notification::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
}

.content-notification {
  background-color: #c9da2d;
  border-radius: 5px;
  padding: 5px;
  width: 350px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>