<template>
  <div class="content-bg-piedra">
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="pt-9 pb-9">
            <p class="txt-title po-bold">
              <span style="color: #c9da2d;">[</span>
              Franchesing process
              <span style="color: #c9da2d;">]</span>
            </p>
            <div class="display-flex align-items-center justify-content-center">
              <p class="txt-description po-regular">
                10 simple steps to becoming your own JEFE
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" md="6" class="pa-0">

          <div class="parallax" >
          <div class="parallax content-img" :style="{ backgroundImage: `${backgroundImage}` }"></div>
          </div>
        </v-col> -->
        <v-col cols="12">
          <div class="" style="">
            <div v-for="(item, index) in array" :key="index" class="content-steps" :id="`content-process-images-${item.id}`">
              <div class="content-right">
              <img :src="item.background" class="img-steps" />
            </div>
          
              <div class="content-left remove-padding" style="padding-left: 50px;">
              <p class="txt-item-id po-bold">{{ item.id }}.</p>
              <p class="txt-item-title po-bold">
                <span style="color: #c9da2d;">[</span>
                {{ item.title }}
                <span style="color: #c9da2d;">]</span>
              </p>
              <p class="txt-item-description po-bold" v-html="item.description" />
              </div>
              
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="content-restant content-bg-piedra" />
  </div>
</template>

<script>
export default {
  name: "ProcessLayout",
  data() {
    return {
      //variables
      backgroundImage: `url(${require("@/assets/images/franchising/process/1.png")})`,
      array: [
        {
          id: 1,
          title: "Complete and Submit the Request for Consideration",
          description:
            "Submitting this request for consideration tells us more about you and your intentions for acquiring a loz takos franchise. after we receive this information, you will be sent a loz takos franchise information package as well as a franchise application.",
          background: [require("@/assets/images/franchising/process/1.png")],
        },
        {
          id: 2,
          title: "Process Approval",
          description:
            "Your application will be reviewed by one of our Business Development Reps. who will call you to discuss it in greater detail.",
          background: [require("@/assets/images/franchising/process/2.png")],
        },
        {
          id: 3,
          title: "Sign of a Non-Disclosure Agreement",
          description:
            "If your application has been approved, we will then present you with LOZ TAKOS Franchise Disclosure Document (FDD) for your review.",
          background: [require("@/assets/images/franchising/process/3.png")],
        },
        {
          id: 4,
          title: "Personal Meeting",
          description:
            "A one-on-one interview will be set up at our corporate office or at any of the available LOZ TAKOS Restaurants. During this meeting you will have the opportunity to ask any questions regarding the franchise agreement, as well as a birds-eye view of our operations.",
          background: [require("@/assets/images/franchising/process/4.png")],
        },
        {
          id: 5,
          title: "Site Selection",
          description:
            "We start looking at potential real estate options with you and help in negotiations of lease agreements.",
          background: [require("@/assets/images/franchising/process/5.png")],
        },
        {
          id: 6,
          title: "Market Preparation",
          description:
            "With the help of your Business Dev. Rep. you will prepare and submit a financial plan using a “Typical” LOZ TAKOS restaurant template. LOZ TAKOS will provide the necessary documents and guidelines to start preparing and opening your new restaurant with the ROAC (Restaurant Opening Accomplishment Chart). <br/> <br/> <br/> LOZ TAKOS Architectural and Design team will submit the conceptual design layout requirements to your locally hired Architect.",
          background: [require("@/assets/images/franchising/process/6.png")],
        },
        {
          id: 7,
          title: "Architectural Design and Construction",
          description:
            "Your local Architect will complete and finalize the drawings according to the local authorities for permitting and certification. Obtain your permit to start construction of your LOZ TAKOS franchise and begin the work process with your General contractor. Finalize construction and request your Certificate of Occupancy, along with other license and permits as needed from the local authorities.",
          background: [require("@/assets/images/franchising/process/7.png")],
        },
        {
          id: 8,
          title: "Training",
          description:
            "Now the fun part starts…. training and pre-opening festivities. Training to be done in a LOZ TAKOS training restaurant.",
          background: [require("@/assets/images/franchising/process/8.png")],
        },
        {
          id: 9,
          title: "Final Preparation for Opening Day",
          description: "Grand Opening of your own LOZ TAKOS Restaurant.",
          background: [require("@/assets/images/franchising/process/9.png")],
        },
        {
          id: 10,
          title: "Post Opening Support",
          description:
            "We work with you in handing over your restaurant to make sure you are ready to be YOUR OWN JEFE!!!",
          background: [require("@/assets/images/franchising/process/10.png")],
        },
      ],
    };
  },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  //   this.handleScroll();
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    // handleScroll: function () {
    //   if (window.scrollY >= 3420) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/1.png")})`;
    //   }
    //   if (window.scrollY >= 4090) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/2.png")})`;
    //   }
    //   if (window.scrollY >= 4540) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/3.png")})`;
    //   }
    //   if (window.scrollY >= 5054) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/4.png")})`;
    //   }
    //   if (window.scrollY >= 5575) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/5.png")})`;
    //   }
    //   if (window.scrollY >= 5987) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/6.png")})`;
    //   }
    //   if (window.scrollY >= 6584) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/7.png")})`;
    //   }
    //   if (window.scrollY >= 7195) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/8.png")})`;
    //   }
    //   if (window.scrollY >= 7641) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/9.png")})`;
    //   }
    //   if (window.scrollY >= 8102) {
    //     this.backgroundImage = `url(${require("@/assets/images/franchising/process/10.png")})`;
    //   }
    // },
  },
};
</script>

<style scoped>
/*#region Test */
.content-restant {
  width: 100%;
  height: 100px;
  background-color: #fff;
}

.content-steps {
  background-color: transparent;
  padding-left: 0px;
  border-left: 0px solid #fff;
  position: relative;
  display: flex;
}

.txt-item-id {
  text-align: left;
  font-size: 100px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #dd4999;
  text-stroke: 2px #dd4999;
  /* Future-proofing */
}

.txt-item-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.txt-item-description {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-title {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-description {
  text-align: center;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0px;
  -webkit-background-clip: text;
  background-image: linear-gradient(360deg, #ffffff 0%, #dd4999 100%);
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.parallax {
  position: relative;
  background-color: red;
  background-attachment: fixed;
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  height: 100%;
}

.content-img {
  width: 80%;
  max-width: 80%;
  position: absolute;
}
.img-steps {
  width: 65%;
  height: 350px;
  object-fit: contain;
}
.content-left{
  width: 50%;
  max-width: 50%;
  min-height: 50%;
  padding: 20px;
  border-left: 2px solid white;

}

.content-right {
  width: 50%;
  max-width: 50%;
  min-height: 50%;
  padding: 50px 39px 50px 30px;
  display:flex;
  justify-content: center;
  padding-bottom: 5%;
  padding-bottom: 5%;
  align-self: center;
}
@media (max-width: 960px) {
  .content-steps {
    padding-left: 0px;
    border: none;
  }
}

/*#endregion Test */

@media (max-width: 600px) {
  .txt-title {
    font-size: 30px;
  }

  .txt-item-title {
    font-size: 20px;
  }

  .txt-item-description {
    font-size: 14px;
  }

  .remove-padding {
    padding: 15px !important;
  }

  .content-steps {
    padding-bottom: 0px;
  }
  .content-right{
    display:none;
    width: 0%;
  }
  .content-left{
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
}
</style>
