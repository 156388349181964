<template>
  <div class="content-whatever">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="pa-0"
        >
          <div
            class="content-catering-image"
            :style="{
            backgroundImage: backgroundImage 
            }"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pa-0"
        >
          <form-layout />
        </v-col>
      </v-row>
    </v-container>
    <!--  -->
  </div>
</template>

<script>
import FormLayout from "@/layouts/catering/Form.vue";

export default {
  name: "WhateverLayout",
  data() {
    return {
      //variables
      backgroundImage: `url(${require("@/assets/images/catering/santo.png")})`,
    };
  },
  components: {
    FormLayout,
  },
};
</script>

<style scoped>
.content-catering-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
  }
}
</style>