var menu = [
  {
    id: 1,
    title: 'Tacos',
    description: 'Soft corn tortilla, onion and cilantro.',
    background: `url(${require("@/assets/images/order/bg-tacos.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Tacos - Pulled Beef', // of barbacoa',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA101',
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Tacos - Pulled Pork',// of carnitas',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA102',
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Tacos - Chipotle Chicken', // of tinga chicken',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA103',
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Tacos - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA104',
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Tacos - Mexican Chorizo', // of chorizo with potatoes',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA105',
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Tacos - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA106',
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Tacos - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'TA107',
      },
      {
        id: 8,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Tacos - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA106',
      },
      {
        id: 10,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Tacos - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TA107',
      },
    ]
  },
  {
    id: 2,
    title: 'Tortas',
    description: 'Bread, black beans, Guacamole, jalapenos, red onion, (choose protein), lettuce and pico de gallo.',
    background: `url(${require("@/assets/images/order/bg-tortas.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Tortas  - Pulled Beef', // of barbacoa',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO201',
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Tortas - Pulled Pork',// of carnitas',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO202'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Tortas - Chipotle Chicken', // of tinga chicken',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO203'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Tortas - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO204'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Tortas - Mexican Chorizo', // of chorizo with potatoes',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO205'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Tortas - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO206'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Tortas - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'TO207'
      },
      {
        id: 8,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Tortas - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO206'
      },
      {
        id: 10,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Tortas - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'TO207'
      },
    ]
  },
  {
    id: 3,
    title: 'Burrito Bowl',
    description: 'Rice, black beans, (choose protein), lettuce, jalapenos, red onion, and sour cream.',
    background: `url(${require("@/assets/images/order/bg-burritos-bowl.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Burrito Bowl - Pulled Beef', // barbacoa
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO301'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Burrito Bowl - Pulled Pork', // carnitas
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO302'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Burrito Bowl - Chipotle Chicken', // of tinga chicken
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO303'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Burrito Bowl - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO304'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Burrito Bowl - Mexican Chorizo', // chorizo with potatoes
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO305'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Burrito Bowl - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO306'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Burrito Bowl - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'BUBO307'
      },
      {
        id: 8,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Burrito Bowl - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO306'
      },
      {
        id: 9,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Burrito Bowl - Crunchy Fish Ceviche', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO306'
      },
      {
        id: 10,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Burrito Bowl - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BUBO307'
      },
    ]
  },
  {
    id: 4,
    title: 'Quesadillas',
    description: 'Cheese, ( protein ), sour cream, red onion and pico de gallo.',
    background: `url(${require("@/assets/images/order/bg-quesadillas.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Quesadillas - Pulled Beef', // of barbacoa',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE401'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Quesadillas - Pulled Pork',// of carnitas',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE402'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Quesadillas - Chipotle Chicken', // of tinga chicken',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE403'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Quesadillas - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE404'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Quesadillas - Mexican Chorizo', // of chorizo with potatoes',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE405'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Quesadillas - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE406'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Quesadillas - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'QUE407'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Quesadillas - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE406'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Quesadillas - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'QUE407'
      },
    ]
  },
  {
    id: 5,
    title: 'Burrito',
    description: 'Rice, black beans, (choose protein), lettuce, jalapenos, red onion, and sour cream.',
    background: `url(${require("@/assets/images/order/bg-burritos.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Burrito - Pulled Beef', // of barbacoa',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU501'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Burrito - Pulled Pork',// of carnitas',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU502'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Burrito - Chipotle Chicken', // of tinga chicken',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU503'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Burrito - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU504'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Burrito - Mexican Chorizo', // of chorizo with potatoes',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU505'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Burrito - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU506'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Burrito - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'BU507'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Burrito - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU506'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Burrito - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'BU507'
      },
    ]
  },
  {
    id: 6,
    title: 'Salad',
    description: 'Lettuce, less rice and black beans, ( protein ), pico de gallo, cheese, corn chips, and sour cream.',
    background: `url(${require("@/assets/images/order/bg-salad.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/tacos/1.png")})`,
        title: 'Salad - Pulled Beef', // of barbacoa',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA601'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/tacos/2.png")})`,
        title: 'Salad - Pulled Pork',// of carnitas',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA602'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/tacos/3.png")})`,
        title: 'Salad - Chipotle Chicken', // of tinga chicken',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA603'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/tacos/4.png")})`,
        title: 'Salad - Grilled Steak', //of asado de res',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA604'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/tacos/5.png")})`,
        title: 'Salad - Mexican Chorizo', // of chorizo with potatoes',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA605'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/order/products/tacos/6.png")})`,
        title: 'Salad - Grilled Chicken', // of pollo asado',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA606'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/order/products/tacos/7.png")})`,
        title: 'Salad - Chipotle Veggies', // of tinga vegetables',
        price: 13,
        vegan: true,
        quantity: 1,
        folio: 'SA607'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Salad - Baja Fish', // Big Fish',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA606'
      },
      {
        id: 7,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Salad - Pork w/ Pineapple', // Carne pastor',
        price: 13,
        vegan: false,
        quantity: 1,
        folio: 'SA607'
      },
    ]
  },
  {
    id: 7,
    title: 'Munchie',
    description: '---',
    background: `url(${require("@/assets/images/order/bg-munchie.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/munchie/1.png")})`,
        title: 'Corn in a cup',
        price: 4,
        vegan: false,
        quantity: 1,
        folio: 'MU701'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/munchie/2.png")})`,
        title: 'Tortilla soup',
        price: 4,
        vegan: false,
        quantity: 1,
        folio: 'MU702'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Nachos',
        price: 6,
        vegan: false,
        quantity: 1,
        folio: 'MU703'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Chips & Salsa',
        price: 6,
        vegan: false,
        quantity: 1,
        folio: 'MU704'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Chips & Guacamole',
        price: 6,
        vegan: false,
        quantity: 1,
        folio: 'MU705'
      },
    ]
  },
  {
    id: 8,
    title: 'Sides',
    description: '---',
    background: `url(${require("@/assets/images/order/bg-sides.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/sides/1.png")})`,
        title: 'Corn Chips',
        price: 3,
        vegan: false,
        quantity: 1,
        folio: 'SI801'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/order/products/sides/2.png")})`,
        title: 'Pico de gallo',
        price: 3,
        vegan: false,
        quantity: 1,
        folio: 'SI802'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/order/products/sides/3.png")})`,
        title: 'Guacamole',
        price: 3,
        vegan: false,
        quantity: 1,
        folio: 'SI803'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/sides/4.png")})`,
        title: 'Beans',
        price: 3,
        vegan: false,
        quantity: 1,
        folio: 'SI804'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/order/products/sides/5.png")})`,
        title: 'Rice',
        price: 3,
        vegan: false,
        quantity: 1,
        folio: 'SI805'
      },
    ]
  },
  {
    id: 9,
    title: 'Dessert',
    description: '---',
    background: `url(${require("@/assets/images/order/bg-munchie.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/order/products/desserts/1.png")})`,
        title: 'Churrito',
        price: 2,
        vegan: false,
        quantity: 1,
        folio: 'DE901'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Horchata Shake',
        price: 2,
        vegan: false,
        quantity: 1,
        folio: 'DE902'
      },
    ]
  },
  {
    id: 10,
    title: 'Drinks',
    description: '---',
    background: `url(${require("@/assets/images/order/bg-drinks.png")})`,
    list: [
      {
        id: 1,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Fountain Drink',
        price: 2.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1001'
      },
      {
        id: 2,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Jarritos',
        price: 3.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1002'
      },
      {
        id: 3,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Mexicoke',
        price: 3.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1003'
      },
      {
        id: 4,
        background: `url(${require("@/assets/images/order/products/drinks/3.png")})`,
        title: 'Horchata',
        price: 3.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1004'
      },
      {
        id: 5,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Jamaica',
        price: 3.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1005'
      },
      {
        id: 6,
        background: `url(${require("@/assets/images/LogoLozTakos.png")})`,
        title: 'Water',
        price: 2.5,
        vegan: false,
        quantity: 1,
        folio: 'DRI1006'
      },
    ]
  }
]
export default menu;
