<template>
  <div id="footer" class="content-footer">
    <v-container fluid class="v-container p-left-container p-right-container">
      <v-row>
        <v-col cols="12" md="6">
          <div class="content-map">
            <!-- <GmapMap :center="center" :zoom="4" :options="mapOptions" style="width: 100%; height: 300px;">
              <GmapMarker :key="index" v-for="(item, index) in markers" :position="item" :icon="iconOptions"
                @click="openWindow(index)" />
              <gmap-info-window @closeclick="window_open = false" :opened="window_open" :position="infowindow" :options="{
                pixelOffset: {
                  width: 0,
                  height: -35
                }
              }">
                <p class="txt-local po-bold">{{ local }}</p>
                <p class="txt-address po-regular">{{ address }}</p>
                <p class="txt-phone po-regular">{{ phone }}</p>
              </gmap-info-window>
            </GmapMap> -->
            <!-- <MglMap :accessToken="mapboxAccessToken" :mapStyle.sync="mapStyle" :center="coordinates" :minZoom="minZoom"
              :maxZoom="maxZoom">
              <MglMarker :coordinates="coordinates" color="#142456" />
            </MglMap> -->
            <div class="content-top"></div>
            <div class="powr-map" id="a472d101_1658177012"></div>
            <div class="content-bottom"></div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <v-container fluid>
              <v-row>
                <v-col v-for="(item, index) in locations" :key="index" cols="12" md="6">
                  <div>
                    <p class="txt-location-local po-bold">{{ item.local }}</p>
                    <p class="txt-location-address po-regular" @click="redirect(index)">{{ item.address }}</p>
                    <p class="txt-location-phone po-regular">{{ item.phone }}</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="border-top-line mgn-content" />
    <v-container>
      <v-row>
        <v-col v-for="(item, index) in redirectRouter" :key="index" class="text-center border-top-line-first" cols="12"
          sm="1" offset-sm="1">
          <div class="redirect-link display-flex align-items-center justify-content-center">
            <p @click="redirectLinks(item.link)" class="txt-link-footer po-regular mb-0">
              {{ item.text }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col class="border-top-line-first" v-for="(item, index) in textsLinks" :key="index" cols="12" md="3">
          <div class="redirect-link display-flex align-items-center justify-content-center">
            <v-icon class="mr-1" color="#dd4999" size="18px">
              {{ item.icon }}
            </v-icon>
            <p @click="redirectLinks(item.link)" class="txt-link-footer po-regular mb-0">
              {{ item.text }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col class="pl-0 pr-0 pb-0">
          <div class="content-powered p-left-container p-right-container">
            <p class="txt-powered mon-regular mb-0">© 2021 Copyright - Loz TaKos.</p>
            <v-spacer />
            <!-- <img src="@/assets/images/footer/facebook.png" alt=""> -->
            <span class="content-btn-socialred">
              <v-btn icon class="ml-2" v-for="(item, index) in aSocialRed" @click="redirectRedsocial(item.url)">
                <v-icon color="white">{{ item.name }}</v-icon>
              </v-btn>
            </span>

            <v-spacer />
            <p @click="redirectUrl('https://sofex.com.mx/')" class="txt-link txt-powered mon-regular mb-0">Powered by
              Sofex Technologies</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  name: "FooterComponent",
  components: { MglMap, MglMarker },

  data() {
    return {
      mapboxAccessToken:
        "pk.eyJ1Ijoic2NvdGhpcyIsImEiOiJjaWp1Y2ltYmUwMDBicmJrdDQ4ZDBkaGN4In0.sbihZCZJ56-fsFNKHXF8YQ",
      mapStyle: "mapbox://styles/mapbox/basic-v11",
      coordinates: [-111.549668, 39.014],
      maxZoom: 15,
      minZoom: 6,
      //variables
      textsLinks: [
        {
          text: "Order now",
          icon: "mdi-shopping-outline",
          link: "Menu",
        },
        {
          text: "Our menu",
          icon: "mdi-silverware",
          link: "Menu",
        },
        {
          text: "Franchising",
          icon: "mdi-store",
          link: "Franchising",
        },
        {
          text: "Contact us",
          icon: "mdi-phone",
          link: "Contact",
        },
      ],
      redirectRouter: [
        {
          text: "Home",
          link: "Home",
        },
        {
          text: "About",
          link: "About",
        },
        {
          text: "Catering",
          link: "Catering",
        },
        {
          text: "Gallery",
          link: "Gallery",
        },
        {
          text: "FAQ",
          link: "Faq",
        },
      ],
      aSocialRed: [
        {
          id: 1,
          name: "mdi-facebook",
          url: "https://www.facebook.com/loztakos/",
        },
        {
          id: 2,
          name: "mdi-instagram",
          url: "https://www.instagram.com/loztakos/",
        }
      ],
      // center: { lat: 56.130366, lng: -106.346771 },
      center: { lat: 49.27903277170898, lng: -122.7975411151732 },
      currentPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 9,
      },
      iconOptions: {
        url: require("@/assets/images/footer/marker-map.png"),
        // size: { width: 60, height: 90, f: "px", b: "px" },
        scaledSize: { width: 30, height: 45, f: "px", b: "px" },
      },
      info_marker: null,
      infowindow: { lat: 0, lng: 0 },
      window_open: false,
      local: "",
      address: "",
      phone: "",
      locations: [
        {
          lat: 49.27903277170898,
          lng: -122.7975411151732,
          local: "COQUITLAM CENTER",
          address: "Unit 2658 – 2929 Barnet Hwy, Coquitlam, BC.",
          phone: "Tel. 604 475 30 00",
        },/* 
        {
          lat: 49.16278945677709,
          lng: -122.57877605935514,
          local: "WALNUT GROVE",
          address: "Unit 1A – 20349 88th Ave. Township of Langley, BC.",
          phone: "Tel. 604 371 22 74",
        },
        {
          lat: 49.26324279708238,
          lng: -123.12148841517359,
          local: "BROADWAY",
          address: "792 West Broadway Vancouver, BC V5Z 1J5",
          phone: "Tel. 604 423 45 25",
        },
        {
          lat: 49.04113250524811,
          lng: -122.26941781703042,
          local: "ABBOTSFORD",
          address: "Unit 402- 2140 Sumas Way Abbotsford, BC V2S2C7",
          phone: "Tel. 604 746 6080",
        }, */
      ],
    };
  },
  beforeMount() {
    this.addMarker();
  },
  methods: {
    redirectLinks: function (url) {
      this.$router
        .push({
          name: url,
        })
        .catch((err) => { });
    },
    redirectUrl: function (url) {
      window.open(url, "blank");
    },
    redirectRedsocial: function (url) {
      window.open(url, "blank");
    },
    addMarker: function () {
      this.markers = [
        {
          lat: 49.27903277170898,
          lng: -122.7975411151732,
          local: "COQUITLAM CENTER",
          address: "Unit 2658 – 2929 Barnet Hwy, Coquitlam, BC.",
          phone: "Tel. 604 475 30 00",
        },
        /* {
          lat: 49.16278945677709,
          lng: -122.57877605935514,
          local: "WALNUT GROVE",
          address: "Unit 1A – 20349 88th Ave. Township of Langley, BC.",
          phone: "Tel. 604 371 22 74",
        },
        {
          lat: 49.26324279708238,
          lng: -123.12148841517359,
          local: "BROADWAY",
          address: "792 West Broadway Vancouver, BC V5Z 1J5",
          phone: "Tel. 604 423 45 25",
        },
        {
          lat: 49.04113250524811,
          lng: -122.26941781703042,
          local: "ABBOTSFORD",
          address: "Unit 402- 2140 Sumas Way Abbotsford, BC V2S2C7",
          phone: "Tel. 604 746 6080",
        }, */
      ];
    },
    openWindow(coords) {
      this.infowindow = {
        lat: this.markers[coords].lat,
        lng: this.markers[coords].lng,
      };
      this.window_open = true;
      this.local = this.markers[coords].local;
      this.address = this.markers[coords].address;
      this.phone = this.markers[coords].phone;
    },
    redirect: function (idx) {
      if (idx == 0) {
        window.open("https://goo.gl/maps/iugkNLqcZJqkiv6U9", "blank");
      } else if (idx == 1) {
        window.open("https://goo.gl/maps/uxzfX2fe5Zs17ctn9", "blank");
      } else if (idx == 2) {
        window.open("https://goo.gl/maps/UFb5ctUvoi2bnUiy8", "blank");
      } else if (idx == 3) {
        window.open("https://goo.gl/maps/j37wFExiCcfBreAc7", "blank");
      }
    },
  },
};
</script>

<style>
.gm-style .gm-style-iw-c {
  background-color: #fff !important;
}
</style>

<style scoped>
.txt-powered {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-powered {
  background-color: #191919;
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.redirect-link p:hover,
.txt-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.mgn-content {
  margin: 0px 0px;
}

.txt-link-footer {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}


.border-bottom-line {
  border-bottom: 1px solid #464646;
}

.txt-location-local {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #c9da2d;
  font-weight: 600;
}

.txt-location-address {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
}

.txt-location-address:hover {
  cursor: pointer;
  text-decoration: underline;
}

.txt-location-phone {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
}

.txt-local {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #c9da2d;
  font-weight: 600;
}

.txt-address {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000;
}

.txt-phone {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000;
}

.content-footer {
  background-color: #000;
  padding: 50px 0px 0px 0px;
}

.border-map {
  border-radius: 10px;
  box-shadow: 0px 20px 40px #0000004b;
  overflow: hidden;
}

.content-map {
  position: relative;

}

.content-top {
  position: absolute;
  height: 30px;
  width: 100%;
  background-color: black;
}

.powr-map {}

.content-bottom {
  position: absolute;
  height: 110px;
  width: 100%;
  background-color: black;
  bottom: 0px;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-powered {
    background-color: #191919;
    padding: 20px 0px;
    display: block;
    align-items: center;
  }

  .content-btn-socialred {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .border-top-line-first {
    border-top: 1px solid #464646;
  }

  .border-top-line {
    border-top: 0px solid #464646;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-powered {
    background-color: #191919;
    padding: 20px 0px;
    display: block;
    align-items: center;
  }

  .content-btn-socialred {
    display: flex;
    width: 100%;
    justify-content: center;
  }
.border-top-line-first {
    border-top: 0px solid #464646;
  }
  .border-top-line {
    border-top: 1px solid #464646;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .border-bottom-line-first {
    border-top: 1px solid #464646;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
  .border-bottom-line {
    border-top: 1px solid #464646;
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
  .border-bottom-line {
    border-top: 1px solid #464646;
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>