<template>
  <div id="content-competitive" class="content-bg-piedra position-relative">
    <v-container
      fluid
      class="v-container"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="remove-padding display-flex align-items-center justify-content-center full-height pt-9 pb-9">
            <div>
              <p style="color: #fff;" class="txt-title-section po-regular">
                <span style="color: #C9DA2D;">[</span>
                Competitive Edge
                <span style="color: #C9DA2D;">]</span>
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "CompetitiveLayout",
};
</script>

<style scoped>
.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: lowercase;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: bold;
}

.content-image-column1 {
  width: 100%;
  height: 100%;
}

.content-image-column1 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-column1 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}


@media (max-width: 600px) {
  .remove-padding {
    padding: 15px !important;
  }
}
</style>