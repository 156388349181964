<template>
  <div
    id="content-banner"
    :style="{ backgroundImage: bannerImage, height: fullHeight }"
    class="content-banner-menu position-relative display-flex align-items-center justify-content-center"
  >
    <div style="position: relative;">
      <div
        v-if="$route.name == 'Menu'"
        class="content-text-menu"
      >
        <img
          class="mark-text po-regular jump"
          src="@/assets/images/order/sello.svg"
          alt=""
        />
      </div>
      <p class="txt-title po-regular">
        <span style="color: #c9da2d;">[</span>
        {{ bannerTitle }}
        <span style="color: #c9da2d;">]</span>
      </p>
      <div v-if="$route.name == 'Franchising'">
        <p class="txt-description po-regular">
          <span style="color: #c9da2d;">“</span>
          READY TO BE YOUR
          <br />
          OWN
          <span style="color: #dd4999;">JEFE</span>
          ?
          <span style="color: #c9da2d;">”</span>
        </p>
      </div>
    </div>
    <div
      v-if="$route.name == 'Menu'"
      class="content-important"
    >
      <img
        width="70px"
        src="@/assets/images/order/important.svg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerComponent",
  props: {
    bannerTitle: String,
    bannerImage: String,
  },
  data() {
    return {
      //variables
      fullHeight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.fullHeight = `${window.innerHeight -60 }px` ;
    },
  },
};
</script>

<style scoped>
.content-important {
  width: 100%;
  bottom: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jump {
  animation: jump 2s linear alternate infinite;
  -moz-animation: jump 2s infinite ease-in-out;
  -webkit-animation: jump 2s infinite ease-in-out;
}

.mark-text {
  position: absolute;
  right: 0;
  top: -30px;
  width: 100px;
}

.content-text-menu {
  position: absolute;
  right: -50px;
  top: -30px;
}

.content-banner-menu {
  height: 450px;
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

.txt-title {
  text-align: left;
  font-size: 9vw;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
  font-weight: 600;
}

.txt-description {
  text-align: center;
  font-size: 3vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
}

@keyframes jump {
  0% {
    top: -30px;
  }
  50% {
    top: -60px;
  }
  100% {
    top: -30px;
  }
}

@-webkit-keyframes jump {
  0% {
    top: -30px;
  }
  50% {
    top: -60px;
  }
  100% {
    top: -30px;
  }
}

@-moz-keyframes jump {
  0% {
    top: -30px;
  }
  50% {
    top: -60px;
  }
  100% {
    top: -30px;
  }
}

@media (max-width: 960px) {
  .txt-title {
    font-size: 40px;
  }

  .txt-description {
    font-size: 20px;
  }
}
</style>
