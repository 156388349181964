<template>
  <div
    class="container-digital-menu position-relative display-flex align-items-center justify-content-center"
  >
    <img class="aguacate-1" src="@/assets/images/order/aguacate.png" alt="" />
    <img class="aguacate-2" src="@/assets/images/order/aguacate.png" alt="" />
    <div>
      <div>
        <p class="txt-title po-regular mb-0">
          <span style="color: #c9da2d;">[</span>
          Digital menu
          <span style="color: #c9da2d;">]</span>
        </p>
      </div>
      <div class="text-center mt-2">
        <v-icon size="40px" color="#fff">
          mdi-tray-arrow-down
        </v-icon>
      </div>
      <div class="display-flex align-items-center justify-content-center mt-5">
        <v-btn
          @click="openFile"
          class="button-download po-medium"
          elevation="0"
        >
          Download PDF
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DigitalMenuLayout',
  data() {
    return {}
  },
  methods: {
    openFile: function () {
      window.open(
        'https://loz-takos-images.s3.amazonaws.com/menu+digital.pdf',
        'blank',
      )
    },
  },
}
</script>

<style scoped>
.aguacate-1 {
  position: absolute;
  width: 150px;
  left: 50px;
  top: -50px;
}

.aguacate-2 {
  position: absolute;
  width: 150px;
  right: 70px;
  bottom: -80px;
  transform: rotate(-45deg);
}

.button-download {
  background-color: #dd4999 !important;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: initial;
  padding: 0px 25px !important;
}

.container-digital-menu {
  background-color: #191919;
  padding: 50px 0px;
  overflow: hidden;
}

.txt-title {
  text-align: left;
  font-size: 40px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  font-weight: 500;
}

@media (max-width: 600px) {
  .aguacate-1,
  .aguacate-2 {
    display: none;
  }
}
</style>
