<template>
  <div :class="(slideNavbar) ? 'content-slide-navbar active' : 'content-slide-navbar display-none'">
    <v-btn icon class="button-close" @click="closeSlideNavbar">
      <v-icon :color="(hoverOrder) ? '#000' : '#fff'">
        mdi-close
      </v-icon>
    </v-btn>
    <v-container class="pa-0" fluid>
      <v-row>
        <v-col cols="12" sm="7" md="5">
          <div class="content-options">
            <div>
              <img src="@/assets/images/navbar/aguacate.png" alt="" width="40px">
            </div>
            <div class="pt-5">
              <p v-if="active == 'Home'" class="text-menu-active po-regular">
                [Home]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Home')">
                Home
              </p>
            </div>
            <div>
              <p v-if="active == 'About'" class="text-menu-active po-regular">
                [About us]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('About')">
                About us
              </p>
            </div>
            <div>
              <p v-if="active == 'Catering'" class="text-menu-active po-regular">
                [Catering]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Catering')">
                Catering
              </p>
            </div>
            <div>
              <p v-if="active == 'Menu'" class="text-menu-active po-regular">
                [Menu]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Menu')">
                Menu
              </p>
            </div>
            <div>
              <p v-if="active == 'Gallery'" class="text-menu-active po-regular">
                [Gallery]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Gallery')">
                Gallery
              </p>
            </div>
            <div>
              <p v-if="active == 'Franchising'" class="text-menu-active po-regular">
                [Franchising]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Franchising')">
                Franchising
              </p>
            </div>
            <div>
              <p v-if="active == 'Contact'" class="text-menu-active po-regular">
                [Contact Us]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Contact')">
                Contact Us
              </p>
            </div>
            <div>
              <p v-if="active == 'Faq'" class="text-menu-active po-regular">
                [FAQ]
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Faq')">
                FAQ
              </p>
              <p v-else class="text-menu-inactive po-regular" @click="redirect('Faq')">
                FAQ
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <!-- <div>
            <v-container fluid>
              <v-row>
                <v-col
                  class="pr-0 pl-0 pt-0"
                  cols="12"
                  md="12"
                >
                  <div
                    @mouseover="hoverOrder = true"
                    @mouseleave="hoverOrder = false"
                    @click="redirect('Menu')"
                    class="display-flex align-items-flex-end content-order-now"
                  >
                    <div>
                      <v-icon
                        :color="(hoverOrder) ? '#000' : '#fff'"
                        size="25px"
                      >
                        mdi-shopping
                      </v-icon>
                      <div class="display-flex align-items-center">
                        <p class="text-order-now mb-0">Order now</p>
                        <v-icon
                          class="ml-1"
                          :color="(hoverOrder) ? '#000' : '#fff'"
                          size="15px"
                        >
                          mdi-arrow-right
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  class="pa-0"
                  cols="12"
                  md="12"
                >
                  <div class="content-contact-us">
                    <v-container
                      class="full-height pa-0"
                      fluid
                    >
                      <v-row class="full-height">
                        <v-col
                          class="pa-0 full-height"
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <div
                            @mouseover="hoverFranchising = true"
                            @mouseleave="hoverFranchising = false"
                            @click="redirect('Franchising')"
                            class="content-franchising display-flex align-items-flex-end full-height"
                          >
                            <div>
                              <v-icon
                                :color="(hoverFranchising) ? '#000' : '#fff'"
                                size="25px"
                              >
                                mdi-store
                              </v-icon>
                              <p class="text-franshesing">Franchising</p>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          class="pa-0 full-height"
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <div
                            @mouseover="hoverContact = true"
                            @mouseleave="hoverContact = false"
                            @click="redirect('Contact')"
                            class="content-contact display-flex align-items-flex-end full-height"
                          >
                            <div>
                              <v-icon
                                :color="(hoverContact) ? '#000' : '#fff'"
                                size="25px"
                              >
                                mdi-phone
                              </v-icon>
                              <p class="text-contact-us">Contact us</p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SlideNavbarComponent",
  data() {
    return {
      //variables
      active: "",
      hoverOrder: false,
      hoverFranchising: false,
      hoverContact: false,
    };
  },
  beforeMount() {
    this.active = this.$route.name;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if(innerWidth > 959)
     this.closeSlideNavbar();
    },
    closeSlideNavbar: function () {
      this.$store.commit("setSlideNavbar", false);
    },
    redirect: function (res) {
      this.closeSlideNavbar();
      // setTimeout(() => {
      this.$router.push({ name: res }).catch((err) => { });
      // }, 150);
    },
  },
  computed: {
    slideNavbar: function () {
      return this.$store.state.slideNavbar;
    },
  },
  watch: {},
};
</script>

<style scoped>
.display-none {
  display: none;
}

.content-order-now {
  background-color: #000;
  padding: 20px;
  height: 50vh;
}

.content-franchising,
.content-contact {
  padding-left: 15px;
}

.content-order-now:hover,
.content-franchising:hover,
.content-contact:hover {
  background-color: #fff;
  cursor: pointer;
}

.content-order-now:hover .text-order-now,
.content-franchising:hover .text-franshesing,
.content-contact:hover .text-contact-us {
  color: #000;
}

.text-order-now,
.text-contact-us,
.text-franshesing {
  text-align: left;
  font-size: 23px;
  letter-spacing: 0px;
  color: #fff;
  text-transform: initial;
  opacity: 1;
}

.text-menu-active {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  -webkit-background-clip: text;
  background-image: linear-gradient(231deg, #ffffff 0%, #dd4999 100%);
  -webkit-text-fill-color: transparent;
  color: #fff;
  display: inline-block;
  padding: 0;
  margin: 0;
  cursor: default;
}

.text-menu-inactive {
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-menu-inactive:hover {
  -webkit-background-clip: text;
  background-image: linear-gradient(231deg, #ffffff 0%, #dd4999 100%);
  -webkit-text-fill-color: transparent;
}

.text-menu-inactive:hover {
  cursor: pointer;
}

.content-options {
  padding: 20px;
  height: 100vh;
  overflow: auto;
}

.content-contact-us {
  padding: 0px 12px;
  height: 50vh;
}

.button-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.content-slide-navbar {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  bottom: 105%;
  position: fixed;
  z-index: 0;
}

.active {
  opacity: 1;
  bottom: 0%;
  z-index: 999;
}

@media (max-width: 600px) {
  .active {
    overflow: auto;
  }

  .button-close {
    color: #fff !important;
  }
}
</style>