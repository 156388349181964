import Vue from "vue";
import Vuex from "vuex";
import PersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    slideNavbar: false,
    slideCart: false,
    alertOrderActive: false,
    alertOrderArr: [],
    productsCartArr: [],
    checkoutArr: [],
  },
  mutations: {
    setSlideNavbar(state, value) {
      state.slideNavbar = value;
    },
    setSlideCart(state, value) {
      state.slideCart = value;
    },
    setAlertOrder(state, value) {
      state.alertOrderActive = value.active;
      state.alertOrderArr = value.arr;
    },
    setProductsCartArr(state, value) {
      state.productsCartArr = value;
    },
    setCheckout(state, value) {
      state.checkoutArr = value;
    }
  },
  actions: {},
  modules: {},
  plugins: [PersistedState()]
});
