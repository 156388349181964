import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

Vue.use(VueMapbox, { mapboxgl: Mapbox });

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, { load: { key: '', libraries: 'places' } })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
