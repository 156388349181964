<template>
  <div style="position:relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <banner-component bannerTitle='CONTACT US' :bannerImage='bannerImage' />
    <form-layout />
    <footer-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
import BannerComponent from "@/components/Banner.vue";
import FormLayout from "@/layouts/contact/Form.vue";

export default {
  name: "ContactView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/contact/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    FormLayout,
    FooterComponent,
  },
};
</script>