<template>
  <div class="content-want-tacos">
    <v-container
      fluid
      class="p-left-container p-right-container"
    >
      <v-row>
        <v-col
          class="pa-0"
          v-for="(item, index) in options"
          :key="index"
          cols="12"
          md="6"
          lg="3"
        >
          <div class="hover-switch">
            <div class="content-texts display-flex align-items-center justify-content-center full-height">
              <div>
                <p
                  class="txt-title po-regular"
                  v-html="item.title"
                />
                <p
                  class="txt-description po-bold"
                  v-html="item.description"
                />
              </div>
            </div>
            <img :src="item.image" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WantTacosLayout",
  data() {
    return {
      //varibales
      options: [
        {
          title: "WANT TACOS",
          description: "WE CAN <br/> HELP YOU",
          image: require("@/assets/images/catering/1.jpg"),
        },
        {
          title: "WANT TO CATER YOUR <br/> CORPORATE EVENT?",
          description: "YES, WE <br/> CAN",
          image: require("@/assets/images/catering/2.jpg"),
        },
        {
          title: "NEED A TASTY TACO FOR THE <br/> SPORTS TEAM?",
          description: "WE CAN <br/> DO THAT",
          image: require("@/assets/images/catering/3.jpg"),
        },
        {
          title: "WANT TO GET TACOS FOR <br/> A PARTY OR WEDDING?",
          description: "WE ALSO <br/> CAN DO IT",
          image: require("@/assets/images/catering/4.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.txt-title {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-description {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.content-want-tacos {
  background-color: #000;
}

.hover-switch {
  position: relative;
  background-color: #000;
  height: 300px;
  width: 100%;
}

.hover-switch img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  /* filter: blur(2px); */
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}

@supports (object-fit: cover) {
  .hover-switch img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.hover-switch:hover > img {
  opacity: 1;
  cursor: default;
}

.hover-switch:hover > .content-texts {
  opacity: 1;
  z-index: 1;
  position: relative;
  cursor: default;
  background-color: #00000070;
}
</style>