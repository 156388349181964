<template>
  <div :class="(slideCart) ? 'content-slide-cart active' : 'content-slide-cart'">
    <div class="display-flex align-items-center full-width full-height">
      <div class="content-backdrop" />
      <div class="content-cart">
        <v-overlay
          class="overlay"
          :value="overlay"
          opacity=".8"
        >
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-btn
          dark
          class="button-close"
          icon
          @click="closeSlideCart"
        >
          <v-icon
            color="#fff"
            size="20px"
          >
            mdi-close
          </v-icon>
        </v-btn>
        <!--Start: Content HTML -->
        <div class="content-txt-cart display-flex align-items-center full-height">
          <p class="txt-cart po-regular">Cart</p>
        </div>
        <div
          class="content-cards"
          :style="{ height: `${screenHeight}px` }"
        >
          <div
            v-for="(item, index) in array"
            :key="index"
            class="card display-flex align-items-center"
          >
            <v-btn
              dark
              class="delete-button"
              icon
              @click="removeItem(item)"
            >
              <v-icon
                size="20px"
                color="red"
              >
                mdi-close
              </v-icon>
            </v-btn>
            <div
              :style="{ backgroundImage: `${item.background}` }"
              class="card-image"
            >
            </div>
            <div class="ml-2 full-width">
              <p class="card-title po-bold mb-0">{{ item.title }}</p>
              <div class="display-flex align-items-center">
                <p class="card-price-txt po-regular mb-0">Price</p>
                <p class="card-price-val po-regular mb-0 ml-2">${{ item.unitPrice.toFixed(2) }} CAD</p>
              </div>
              <div class="display-flex align-items-center">
                <p class="card-subtotal-txt po-regular mb-0">Subtotal</p>
                <p class="card-subtotal-val po-bold mb-0 ml-2">${{ item.subtotal.toFixed(2) }} CAD</p>
              </div>
            </div>
            <div class="display-flex align-items-flex-end justify-content-flex-end full-height">
              <input
                :value="item.quantityCart"
                ref="inputs"
                type="text"
                class="list-input po-regular"
                @keypress="soloNumbers"
                @keyup="ajustAmount($event, index, item)"
                @blur="noEmpty(index)"
                maxlength="2"
              >
              <v-btn
                @click="updateQuantity(index, item)"
                class="button-save ml-1"
                ref="buttons"
                style="display: none;"
              >
                <v-icon>
                  mdi-content-save-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
          <p
            class="txt-empty-cart po-medium"
            @click="$store.commit('setProductsCartArr', []); $store.commit('setSlideCart', false)"
          >Do you want to empty the cart?</p>
        </div>
        <div class="content-txt-total display-flex align-items-center full-height">
          <p class="txt-total po-regular">TOTAL</p>
          <p class="txt-total po-regular ml-5">${{ total.toFixed(2) }} CAD</p>
          <v-spacer />
          <v-btn
            @click="redirectCheckout"
            :disabled="validationForm"
            class="button-checkout po-regular"
          >
            Checkout
            <v-icon
              class="ml-2"
              size="16px"
              color="#fff"
            >
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
        <!--End: Content HTML -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideCartComponent",
  data() {
    return {
      //variables
      array: [],
      screenHeight: 0,
      total: 0,
      overlay: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    redirectCheckout: function () {
      this.$store.commit("setCheckout", this.array);
      this.closeSlideCart();
      this.$router.push({
        name: "Checkout",
      });
    },
    updateQuantity: function (index, obj) {
      this.overlay = true;
      let new_hangover = this.arr.filter((e) => e.folio != obj.folio);
      console.log(new_hangover);
      new_hangover.push({
        id: obj.id,
        background: obj.background,
        title: obj.title,
        price: obj.price,
        vegan: obj.vegan,
        quantity: this.$refs.inputs[index].value,
        folio: obj.folio,
      });

      this.$refs.buttons[index].$el.style = "display: none";
      this.$store.commit("setProductsCartArr", new_hangover);
      this.array = this.processHangover(new_hangover);
      this.getTotal(this.array);
    },
    removeItem: function (obj) {
      let new_hangover = this.arr.filter((e) => e.folio != obj.folio);
      this.$store.commit("setProductsCartArr", new_hangover);
      this.array = this.processHangover(new_hangover);
      this.getTotal(this.array);
    },
    handleResize: function () {
      this.screenHeight = window.innerHeight - 140;
    },
    closeSlideCart: function () {
      this.$store.commit("setSlideCart", false);
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    ajustAmount: function (evt, index, order) {
      if (this.$refs.inputs[index].value == "00") {
        this.$refs.inputs[index].value = 1;
      } else if (this.$refs.inputs[index].value == "0") {
        this.$refs.inputs[index].value = 1;
      } else {
        this.$refs.inputs[index].value = evt.target.value;
      }

      this.$refs.buttons[index].$el.style = "display: block";
      // this.noEmpty(index);d
      // this.updateQuantity(index, order);
    },
    noEmpty: function (index) {
      if (this.$refs.inputs[index].value == "") {
        this.$refs.inputs[index].value = 1;
      }
      this.teclea = false;
    },
    getTotal: function (arr) {
      var total = 0;
      for (var i in arr) {
        total += arr[i].subtotal;
      }
      this.total = total;
    },
    processHangover: function (val) {
      let new_arr = val.reduce((a, b) => {
        let item = a.find((el) => el.folio == b.folio);
        if (!item)
          return [
            ...a,
            {
              folio: b.folio,
              background: b.background,
              title: b.title,
              id: b.id,
              price: b.price,
              vegan: b.vegan,
              historial: [b],
              quantityCart: parseInt(b.quantity),
              unitPrice: parseFloat(b.price),
              subtotal: parseFloat(b.price) * parseInt(b.quantity),
            },
          ];

        item.historial.push(b);
        item.quantityCart = item.quantityCart + parseInt(b.quantity);
        item.subtotal = parseFloat(b.price) * item.quantityCart;
        return a;
      }, []);
      return new_arr;
    },
  },
  computed: {
    slideCart: function () {
      return this.$store.state.slideCart;
    },
    arr: function () {
      return this.$store.state.productsCartArr;
    },
    validationForm: function () {
      return this.arr.length == 0;
    },
  },
  watch: {
    slideCart: function () {
      if (this.slideCart) {
        this.array = this.processHangover(this.arr);
        this.getTotal(this.array);
      }
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 1000);
    },
  },
};
</script>

<style scoped>
.txt-empty-cart {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.txt-empty-cart:hover {
  text-decoration: underline;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.button-checkout {
  background-color: #dd4999 !important;
  border-radius: 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: initial;
}

.button-save {
  background-color: #dd4999 !important;
  min-width: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  padding: 0px !important;
}

.list-input {
  height: 40px;
  width: 50px !important;
  color: #fff;
  font-size: 14px;
  background-color: #ffffff20;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  text-align: center;
}

.card-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #c9da2d;
  opacity: 1;
}

.card-price-txt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-price-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-subtotal-txt {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-price-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
}

.card-subtotal-val {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #dd4999;
  opacity: 1;
}

.card {
  background-color: #191919;
  border-radius: 0px;
  padding: 20px 10px;
  border-bottom: 1px solid #4b4b4b;
  position: relative;
}

.delete-button {
  position: absolute;
  top: -2px;
  right: 5px;
}

.card-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 60px;
  width: 100px;
  border-radius: 10px;
}

.txt-cart {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.txt-total {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.content-txt-cart {
  background-color: #000;
  height: 70px;
  width: 100%;
  padding: 10px;
}

.content-cards {
  padding: 10px;
  background-color: #191919;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 70px;
}

/* width */
.content-cards::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.content-cards::-webkit-scrollbar-track {
  background: #707070;
  border-radius: 10px;
}

/* Handle */
.content-cards::-webkit-scrollbar-thumb {
  background: #c9da2d;
}

/* Handle on hover */
.content-cards::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

.content-txt-total {
  padding: 10px;
  background-color: #000;
  height: 70px;
  width: 100%;
}

.button-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.content-backdrop {
  height: 100%;
  width: calc(100% - 350px);
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: #ffffff25;
}

.content-cart {
  height: 100%;
  width: 450px;
  background-color: #000;
  position: relative;
}

.content-slide-cart {
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  left: 105%;
  position: fixed;
  z-index: 0;
}

.active {
  opacity: 1;
  left: 0%;
  z-index: 999999;
}
</style>