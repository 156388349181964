<template>
  <div class="content-bg-piedra position-relative">
    <v-container
      fluid
      class="v-container"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="remove-padding display-flex align-items-center justify-content-center full-height pl-9 pr-9">
            <div class="pt-5 pb-5">
              <p class="txt-title-section po-regular">
                <span style="color: #C9DA2D;">[</span>
                Our Mission
                <span style="color: #C9DA2D;">]</span>
              </p>
              <p class="txt-description po-regular">Serve traditional mexican food with a particular urban ambience… special decor…great service… At affordable prices for all…making eating out an experience beyond just eating.</p>
              <div style="margin-top: 100px">
                <p class="txt-title-section po-regular">
                  <span style="color: #C9DA2D;">[</span>
                  Our Vision
                  <span style="color: #C9DA2D;">]</span>
                </p>
                <p class="txt-description po-regular">To become the first truly mexican restaurant chain to serve mexican street food in a modern urban atmosphere.</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          md="6"
        >
          <div class="content-image-column1 full-height">
            <img
              src="@/assets/images/about/comiendo.png"
              alt=""
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "VisionMisionLayout",
};
</script>

<style scoped>
.txt-description {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
  font-weight: bold;
}

.content-image-column1 {
  width: 100%;
  height: 100%;
}

.content-image-column1 img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-column1 img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .txt-description {
    font-size: 18px;
  }

  .remove-padding {
    padding: 15px !important;
  }
}
</style>