<template>
  <div style="position:relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <banner-component
      bannerTitle='MENU'
      :bannerImage='bannerImage'
    />
    <digital-menu-layout />
    <tabs-layout />
    <footer-component />
    <alert-order-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
import BannerComponent from "@/components/Banner.vue";
import TabsLayout from "@/layouts/order/Tabs.vue";
import AlertOrderComponent from "@/components/AlertOrder.vue";
import DigitalMenuLayout from "@/layouts/order/DigitalMenu.vue";

export default {
  name: "OrderView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/order/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    TabsLayout,
    AlertOrderComponent,
    FooterComponent,
    DigitalMenuLayout,
  },
};
</script>