<template>
  <div style="position:relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <banner-component
      bannerTitle='FAQ'
      :bannerImage='bannerImage'
    />
    <question-layout />
    <footer-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import BannerComponent from "@/components/Banner.vue";
import QuestionLayout from "@/layouts/faq/Question.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "FaqView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/faq/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    // ParallaxLayout,
    QuestionLayout,
    FooterComponent,
  },
};
</script>