<template>
  <div class="content-form position-relative">
    <v-container
      fluid
      class="p-left-container p-right-container"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <p class="text-contact po-regular">
              Here at Loz Takos we are focused in providing all guests quality, authentic Mexican street food as fast as we can. 
              Offering a wide range of menu items, ensuring all Tako lovers are taken care of.
              Give our chef Fernando a call at +1 (778) 792-2204 or send us a message, and we will contact you as soon as possible. From our Kitchen to yours.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <form @submit.prevent="sendEmail">
            <div>
              <p class="txt-label po-regular">Name*</p>
              <v-text-field
                v-model="nombre"
                name="nombre"
                placeholder="Name"
                solo
                dense
                hide-details=""
                class="mb-5 input-style"
                background-color="#262626"
              />
            </div>
            <div>
              <p class="txt-label po-regular">Email*</p>
              <v-text-field
                v-model="correo"
                name="correo"
                placeholder="Email"
                solo
                dense
                hide-details=""
                class="mb-5 input-style"
                background-color="#262626"
              />
            </div>
            <div>
              <p class="txt-label po-regular">Phone*</p>
              <v-text-field
                v-model="telefono"
                name="telefono"
                placeholder="Phone"
                solo
                dense
                hide-details=""
                class="mb-5 input-style"
                background-color="#262626"
                @keypress="soloNumbers"
              />
            </div>
            <div>
              <p class="txt-label po-regular">Tell us about your event*</p>
              <v-textarea
                v-model="comentarios"
                name="comentarios"
                placeholder="Tell us about your event*"
                solo
                dense
                class="mb-3 input-style"
                rows="4"
                counter="500"
                background-color="#262626"
              />
            </div>
            <div class="content-button-contact display-flex align-items-center justify-content-center">
              <v-btn
                type="submit"
                :loading="bLoading"
                :disabled="!validateForm"
                class=" button-send"
              >
                Submit
              </v-btn>
            </div>
          </form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "FormLayout",
  data() {
    return {
      //variables
      nombre: "",
      correo: "",
      telefono: "",
      comentarios: "",
      bLoading: false,
    };
  },
  methods: {
    sendEmail: function (e) {
      this.bLoading = true;

      emailjs
        .sendForm(
          "service_nhbz5lo",
          "template_ihais4g",
          e.target,
          "user_PIpQsjbgK2NtqIL9VXeoQ"
        )
        .then(
          (result) => {
            this.$swal(
              "Success!",
              "The email has been sent successfully",
              "success"
            );

            this.bLoading = false;
            this.nombre = "";
            this.correo = "";
            this.telefono = "";
            this.comentarios = "";
          },
          (error) => {
            this.$swal(
              "Error!",
              "There are problems sending the email",
              "success"
            );

            this.bLoading = false;
            this.nombre = "";
            this.correo = "";
            this.telefono = "";
            this.comentarios = "";
          }
        );
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
  computed: {
    validateForm: function () {
      return (
        this.nombre != "" &&
        this.correo != "" &&
        this.telefono != "" &&
        this.comentarios != ""
      );
    },
  },
};
</script>

<style>
.content-button-contact .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #dd4999 !important;
  opacity: 0.5;
  pointer-events: all;
  cursor: no-drop;
}

.content-form .input-style input::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea::placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style textarea::-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 0.2;
}

.content-form .input-style input {
  color: #fff !important;
  font-size: 14px;
}

.content-form .input-style textarea {
  color: #fff !important;
  font-size: 14px;
}
</style>

<style scoped>
.text-contact {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-form {
  background-color: #000;
  padding: 100px 0px 0px 0px;
  overflow: hidden;
}

.button-send {
  width: 100%;
  background: #dd4999 0% 0% no-repeat padding-box !important;
  border-radius: 30px;
  text-align: left;
  font-size: 16px !important;
  text-transform: initial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.txt-label {
  text-align: left;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0px;
}

@media (max-width: 960px) {
  .text-contact {
    font-size: 20px;
  }
}
</style>