<template>
  <div class="content-logo">
    <v-container
      class="p-left-container p-right-container"
      fluid
    >
      <v-row>
        <v-col>
          <div class="display-flex align-items-center justify-content-center">
            <div class="content-image">
              <img
                src="@/assets/images/franchising/logo.png"
                alt=""
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <p
              class="text-description po-bold mt-9"
              v-html="text"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ParallaxLayout",
  data() {
    return {
      //variables
      text: "LOZ TAKOS is a privately-owned Canadian restaurant franchise system. Our dedicated franchisees are <br/> hands-on owner-operators that are both personally and financially committed to their business, resulting <br/> in a maximized level of customer service. <br/> <br/> <br/> As we continue our expansion of the brand we are always searching for qualified individuals to join our <br/> franchise community. We invite you to review our franchising information to determine if this opportunity <br/> is right for you to become a JEFE.",
    };
  },
  components: {},
};
</script>

<style scoped>
.text-description {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-logo {
  background-color: #000000;
  padding: 50px;
}

.content-image {
  width: 350px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .text-description {
    font-size: 16px;
  }

  .content-logo {
    padding: 20px;
  }
}
</style>
