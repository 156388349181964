<template>
  <div style="position:relative;">
    <slide-navbar-component />
    <slide-cart-component />
    <navbar-component />
    <banner-component
      bannerTitle='GALLERY'
      :bannerImage='bannerImage'
    />
    <photos-layout />
    <footer-component />
  </div>
</template>

<script>
import SlideNavbarComponent from "@/components/SlideNavbar.vue";
import SlideCartComponent from "@/components/SlideCart.vue";
import NavbarComponent from "@/components/Navbar.vue";
import BannerComponent from "@/components/Banner.vue";
import PhotosLayout from "@/layouts/gallery/Photos.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "GalleryView",
  data() {
    return {
      //variables
      bannerImage: `url(${require("@/assets/images/gallery/banner.png")})`,
    };
  },

  components: {
    SlideNavbarComponent,
    SlideCartComponent,
    NavbarComponent,
    BannerComponent,
    PhotosLayout,
    FooterComponent,
  },
};
</script>